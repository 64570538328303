import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private isLoading: boolean = false;
    private loadingText = 'Loading';

    get loading(): boolean {
        return this.isLoading;
    }

    get text(): string {
        return this.loadingText;
    }

    show(text: string = 'Loading...'): void {
        this.isLoading = true;
        this.loadingText = text;
    }

    hide(): void {
        this.isLoading = false;
        this.loadingText = '';
    }
}
