import { Injectable } from '@angular/core';
import {
    Contact,
    IChatMessage,
    IChatRoom,
    IMessage,
    Profile,
} from 'app/types/chat/chat.types';
import {
    BehaviorSubject,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../../core/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    chatRooms = this._socket.fromEvent('joinAll');
    chatHistory = this._socket.fromEvent('getHistory');
    onMessage = this._socket.fromEvent('msg');
    onChatId = this._socket.fromEvent('joinLoadFromNetwork');
    onMsgSeen = this._socket.fromEvent('msg_seen');
    private _chat: BehaviorSubject<IChatRoom> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<IChatRoom[]> = new BehaviorSubject(null);
    private _lastMessageMap: Map<string, IChatMessage> = new Map<
        string,
        IChatMessage
    >();
    private _lastMessages: BehaviorSubject<ReadonlyMap<string, IChatMessage>> =
        new BehaviorSubject<ReadonlyMap<string, IChatMessage>>(
            this._lastMessageMap,
        );
    private _contact: BehaviorSubject<Contact> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
    private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);
    private _countMessages: number = 0;
    private _unreadMessages: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(
        private _socket: Socket,
        private _auth: AuthService,
        private _httpClient: HttpClient,
        private _userService: UserService,
    ) {}

    /**
     * Getter for chat
     */
    get chat$(): Observable<IChatRoom> {
        return this._chat.asObservable();
    }

    get unreadMessages$(): Observable<number> {
        return this._unreadMessages.asObservable();
    }

    get lastMessageMap(): Map<string, IChatMessage> {
        return this._lastMessageMap;
    }

    get lastMessages$(): Observable<ReadonlyMap<string, IChatMessage>> {
        return this._lastMessages.asObservable();
    }

    createChatRoom(
        loadId: string,
        transporterId: string,
        isChildChat: boolean,
    ): void {
        this._socket.emit('joinLoadFromNetwork', {
            token: this._auth.accessToken,
            loadId: loadId,
            transporterId: transporterId,
            isChildChat: isChildChat,
        });
    }

    startChat(): void {
        this._socket.emit('joinAllPaginate', {
            token: this._auth.accessToken,
            options: {
                filter: {},
                paginate: { page: 0, pageSize: 500 },
            },
        });
    }

    setLastMessage(chatId: string, messageObj: IChatMessage): void {
        this._lastMessageMap.set(chatId, messageObj);
        this._lastMessages.next(this._lastMessageMap);
    }

    setLastMessageMap(messageMap: Map<string, IChatMessage>): void {
        this._lastMessageMap = new Map(messageMap);
        this._lastMessages.next(this._lastMessageMap);
    }

    updateMessageCounter(counter: number): void {
        this._countMessages = counter;
        this._unreadMessages.next(this._countMessages);
    }

    getHistory(chatId: string): void {
        this._socket.emit('getHistory', {
            token: this._auth.accessToken,
            chatId: chatId,
        });
    }

    getChatRoomByLoadId(chatRooms: IChatRoom[], load: any): IChatRoom[] {
        return chatRooms.filter((chatRoom) => chatRoom.loadId === load?.id);
    }

    getChatRoomByCompanyId(
        chatRooms: IChatRoom[],
        companyId: string,
    ): IChatRoom {
        return chatRooms.filter(
            (chatRoom) => chatRoom.carrierId === companyId,
        )[0];
    }

    sendMessage(message: IMessage): void {
        this._socket.emit(
            'msg',
            {
                token: this._auth.accessToken,
                chatId: message.chatId,
                msg: message.message,
                files: message.files,
            },
            () => this.getHistory(message.chatId),
        );
    }

    setMsgSeen(chatId, loadId, msgId): void {
        this._socket.emit('msg_seen', {
            token: this._auth.accessToken,
            chatId: chatId,
            loadId: loadId,
            id: msgId,
        });
    }

    /**
     * Get contacts
     */
    getContacts(): Observable<any> {
        return this._httpClient.get<Contact[]>('api/apps/chat/contacts').pipe(
            tap((response: Contact[]) => {
                this._contacts.next(response);
            }),
        );
    }

    /**
     * Get chat
     *
     */
    getChatById(chatId: string): Observable<any> {
        this.getHistory(chatId);
        return this.chatHistory.pipe(
            take(1),
            map((chat: IChatRoom) => {
                // Update the chat
                this._chat.next(chat);

                // Return the chat
                return chat;
            }),
            switchMap((chat) => {
                if (!chat) {
                    return throwError(
                        'Could not found chat with id of ' + chatId + '!',
                    );
                }

                return of(chat);
            }),
        );
    }

    resetChat(): void {
        this._chat.next(null);
    }
}
