import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/services/user/user.service';
import {catchError, forkJoin, map, Observable, of, switchMap, throwError, timeout} from 'rxjs';
import { environment } from '../../../environments/environment';
import { Apollo } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { QUERY_GET_USER_DATA } from '../graphql/queries';
import {
  ACCEPT_FRIENDSHIP,
  CHANGE_PASS_WITH_CODE,
  FORGOT_PASS,
  MUTATION_LOGIN,
  MUTATION_LOGIN_AS,
  MUTATION_REFRESH_TOKEN,
  MUTATION_REGISTER,
  SEND_CONFIRMATION_MAIL,
} from '../graphql/mutations';
import { CometChat } from '@cometchat-pro/chat';
import { IClient } from '../../types/client/client.types';
import { ILogin } from '../../types/auth/auth.types';
import { CompanyRoleEnum } from '../../types/company/company.types';
import {User, UserPermissionsType} from '../../types/user/user.types';
import { OneSignalService } from 'app/services/one-signal/one-signal.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;
  private authKey: string = environment.comeChatAuthKey;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _cookieService: CookieService,
    private _router: Router,
    private _apollo: Apollo,
    private oneSignalService: OneSignalService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('yl_accessToken', token);
  }

  set refreshToken(token: string) {
    localStorage.setItem('yl_refreshToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('yl_accessToken') ?? '';
  }

  get refreshToken(): string {
    return localStorage.getItem('yl_refreshToken') ?? '';
  }

  set _adminAuthData(adminToken: ILogin) {
    localStorage.setItem('_adminAuthData', JSON.stringify(adminToken));
  }

  get _adminAuthData(): ILogin {
    return JSON.parse(localStorage.getItem('_adminAuthData'));
  }

  set _loggedInAs(clientObject: IClient) {
    localStorage.setItem('_loggedInAs', JSON.stringify(clientObject));
  }

  set _loggedInAsCookie(clientToken: ILogin) {
    this._cookieService.set('_token', clientToken.token, 0, '/');
    this._cookieService.set('_refreshToken', clientToken.refreshToken, 0, '/');
    this._cookieService.set('_tokenExp', clientToken.exp, 0, '/');
    this._cookieService.set('_refreshExp', clientToken.expRefresh, 0, '/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string, network: boolean): Observable<any> {
    return this._apollo
      .mutate({
        mutation: FORGOT_PASS,
        variables: {
          email,
          network,
        },
      })
      .pipe(map((result: any) => result.data.forgotPass));
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this._apollo
      .mutate({
        mutation: MUTATION_LOGIN,
        variables: {
          email: credentials.email,
          pass: credentials.password,
          pageType: 'network',
        },
      })
      .pipe(
        switchMap((response: any) => {
          this.accessToken = response.data.login.token;
          this.refreshToken = response.data.login.refreshToken;
          forkJoin({
            userData: this.getUserData(response.data.login.userId),
            roles: this.getUserRoles(response.data.login.userId)
          }).pipe(
            map(({ userData, roles}) => {
              this._userService.user = {
                ...userData,
                permissions: roles,
              };
              this._authenticated = true;
              this.handleCometChatAuth(userData);
              this.handleOneSignalAuth(userData);
              return true;
            })
          );
          return of(response);
        }),
      );
  }

  signInAs(client: IClient): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_LOGIN_AS,
        variables: {
          input: client.email,
        },
      })
      .pipe(
        switchMap((response: any) => {
          this._adminAuthData = {
            token: this.accessToken,
            refreshToken: this.refreshToken,
          };
          this.accessToken = response.data.loginAs.token;
          this.refreshToken = response.data.loginAs.refreshToken;
          this._loggedInAs = client;
          this._router
            .navigateByUrl('/social')
            .then(() => window.location.reload());
          return of(true);
        }),
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    return this._apollo
      .mutate({
        mutation: MUTATION_REFRESH_TOKEN,
        variables: {
          refreshToken: this.refreshToken,
        },
      })
      .pipe(
        switchMap((response: any) => {
          if (response.errors && response.errors.length > 0) {
            this._authenticated = false;
            return of(false);
          }

          this.accessToken = response.data.refreshToken.token;
          this.refreshToken = response.data.refreshToken.refreshToken;

          const userId = response.data.refreshToken.userId;

          return forkJoin({
            userData: this.getUserData(userId),
            roles: this.getUserRoles(userId)
          }).pipe(
            map(({ userData, roles}) => {
              this._userService.user = {
                ...userData,
                permissions: roles,
              };
              this._authenticated = true;
              this.handleOneSignalAuth(userData);
              return true;
            })
          );
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('yl_accessToken');
    localStorage.removeItem('yl_refreshToken');

    //Log out of OneSignal
    this.oneSignalService.logout();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   */

  signUp(
    email: string,
    pass: string,
    name: string,
    company: string,
    phone: string,
    cui: string,
    address: string,
    city: string,
    county: string,
    country: string,
    role: CompanyRoleEnum,
    meta: any,
    network: boolean,
    referral: string,
  ): Observable<any> {
    return this._apollo
      .mutate({
        mutation: MUTATION_REGISTER,
        variables: {
          email,
          pass,
          name,
          company,
          phone,
          cui,
          address,
          city,
          county,
          country,
          role,
          meta,
          network,
          referral,
        },
      })
      .pipe(map((result: any) => result.data.register));
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  sendConfirmationEmail(email: string): Observable<any> {
    return this._apollo
      .mutate({
        mutation: SEND_CONFIRMATION_MAIL,
        variables: {
          email,
        },
      })
      .pipe(map((result: any) => result.data.sendConfirmationMail));
  }

  /**
   * Accept Friendship
   */
  acceptFriendship(referralId: string): Observable<any> {
    return this._apollo
      .mutate({
        mutation: ACCEPT_FRIENDSHIP,
        variables: {
          referralId,
        },
      })
      .pipe(map((result: any) => result.data.acceptFriendship));
  }

  /**
   * Accept Friendship
   */
  changePassWithCode(
    emailCode: string,
    pass: string,
    network: boolean,
  ): Observable<any> {
    return this._apollo
      .mutate({
        mutation: CHANGE_PASS_WITH_CODE,
        variables: {
          emailCode,
          pass,
          network,
        },
      })
      .pipe(map((result: any) => result.data.changePassWithCode));
  }

  goBack(): void {
    this.accessToken = this._adminAuthData.token;
    this.refreshToken = this._adminAuthData.refreshToken;
    localStorage.removeItem('_adminAuthData');
    localStorage.removeItem('_loggedInAs');
    this._router
      .navigateByUrl('/admin/clients')
      .then(() => window.location.reload());
  }

  handleCometChatAuth(loggedUser: User): void {
    CometChat.getLoggedinUser().then(
      (user: CometChat.User) => {
        if (!user) {
          CometChat.login(loggedUser.id, this.authKey).then(
            (res: CometChat.User) => {},
            (error: CometChat.CometChatException) => {
              const newUser = new CometChat.User(loggedUser.id);
              newUser.setName(loggedUser.name);
              CometChat.createUser(newUser, this.authKey).then(
                (result) => {
                  CometChat.login(loggedUser.id, this.authKey).then();
                },
                (err) => {
                  console.log(err);
                },
              );
            },
          );
        }
      },
      (error: CometChat.CometChatException) => {
        console.log('Error Occurred', { error });
      },
    );
  }

  async handleOneSignalAuth(loggedUser: User): Promise<void> {
    this.oneSignalService.login(loggedUser.id);
  }

  getUserRoles(userId: string): Observable<UserPermissionsType> {
    return this._httpClient
      .get<UserPermissionsType>(`${environment.workloadApiUrl}/network/users/roles`, {})
      .pipe(
        timeout(5000),
        catchError((error) => {
          console.error('Error fetching roles:', error);
          return of({} as UserPermissionsType);
        })
      );
  }

  getUserData(userId: string): Observable<any> {
    return this._apollo.query({
      query: QUERY_GET_USER_DATA,
      variables: {
        userId: userId,
      },
    }).pipe(
      switchMap((response: any) => {
        if (response.errors && response.errors.length > 0) {
          return throwError(() => new Error('Failed to fetch user data.'));
        }
        this._userService.user = response.data.getUserData;
        this._authenticated = true;
        this.handleCometChatAuth(response.data.getUserData);
        this.handleOneSignalAuth(response.data.getUserData);
        return of(response.data.getUserData);
      })
    );
  }
}
