import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { moduleGuard } from './core/auth/guards/app-module.guard';
import { AppModulesEnum } from './types/user/user.types';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/social'
  { path: '', pathMatch: 'full', redirectTo: 'social' },

  // Redirect signed-in user to the '/social'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'social' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.routes'
          ),
      },
      {
        path: 'change-pass-with-code/:emailCode',
        loadChildren: () =>
          import(
            'app/modules/auth/change-pass-with-code/change-pass-with-code.routes'
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.routes'),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
      },
      {
        path: 'validate-email/:code',
        loadChildren: () =>
          import('app/modules/auth/validate-email/validate-email.routes'),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/landing/home/home.routes'),
      },
      {
        path: 'external-news',
        loadChildren: () =>
          import(
            'app/modules/pages/communication/external-news/external-news.routes'
          ),
      },
      {
        path: 'shipment',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/shared/single-shipment/single-shipment.routes'
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // Communication
      {
        path: 'social',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('app/modules/pages/communication/social/social.routes'),
          },
          {
            path: 'news',
            loadChildren: () =>
              import('app/modules/pages/communication/news/news.routes'),
          },
        ],
      },
      {
        path: 'company-news',
        loadChildren: () =>
          import(
            'app/modules/pages/communication/company-news/company-news.routes'
          ),
      },
      {
        path: 'organisation',
        loadChildren: () =>
          import('app/modules/pages/communication/offices/offices.routes'),
      },
      {
        path: 'conversations',
        loadChildren: () =>
          import('app/modules/pages/communication/chat/chat.routes'),
      },

      // Content
      {
        path: 'content',
        canActivate: [moduleGuard],
        data: {
          module: AppModulesEnum.CONTENT,
        },
        children: [
          {
            path: 'file-manager',
            loadChildren: () =>
              import(
                'app/modules/pages/content/file-manager/file-manager.routes'
              ),
          },
        ],
      },

      // CRM
      {
        path: 'crm',
        canActivate: [moduleGuard],
        data: {
          module: AppModulesEnum.CRM,
        },
        children: [
          {
            path: 'accounts',
            loadChildren: () =>
              import('app/modules/pages/crm/accounts/accounts.routes'),
          },
          {
            path: 'contacts',
            loadChildren: () =>
              import('app/modules/pages/crm/contacts/contacts.routes'),
          },
          {
            path: 'opportunities',
            loadChildren: () =>
              import(
                'app/modules/pages/crm/opportunities/opportunities.routes'
              ),
          },
          {
            path: 'tasks',
            loadChildren: () =>
              import('app/modules/pages/crm/tasks/tasks.routes'),
          },
        ],
      },

      // Operational
      /* Sea Freight */
      {
        path: 'sea-freight',
        children: [
          {
            path: 'shipments',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-shipment/sea-freight-shipment.routes'
              ),
          },
          {
            path: 'partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-transporter/sea-freight-transporter.routes'
              ),
          },
          {
            path: 'banking-partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/shared/banking-partners/banking-partners.routes'
              ),
          },
          {
            path: 'invoices',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-invoice/sea-freight-invoice.routes'
              ),
          },
          {
            path: 'financials',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-financial/sea-freight-financial.routes'
                ),
          },
        ],
      },
      /* Air Freight */
      {
        path: 'air-freight',
        children: [
          {
            path: 'shipments',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-shipment/air-freight-shipment.routes'
              ),
          },
          {
            path: 'air-transport-order',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-order/air-freight-order.routes'
                ),
          },
          {
            path: 'partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-transporter/air-freight-transporter.routes'
              ),
          },
          {
            path: 'banking-partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/shared/banking-partners/banking-partners.routes'
              ),
          },
          {
            path: 'invoices',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-invoice/air-freight-invoice.routes'
              ),
          },
          {
            path: 'financials',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-financial/air-freight-financial.routes'
                ),
          },
        ],
      },
      {
        path: 'financial-costs',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/financials/financials.routes'
            ),
      },

      // Profile
      {
        path: 'account',
        loadChildren: () =>
          import('app/modules/pages/user-account/user-account.routes'),
      },
    ],
  },
];
