import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | string | number): string {
    const now = moment();
    const creationTime = moment.unix(Number(value)).isValid() ?
      moment.unix(Number(value) / 1000) :
      new Date(value).getTime();
    const duration = moment.duration(now.diff(creationTime)).humanize();
    return `${duration}`;
  }
}
