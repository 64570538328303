import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../../../types/user/user.types';
import { AuthService } from '../../../core/auth/auth.service';
import { FuseConfigService } from '../../../../@fuse/services/config';
import { CometChat } from '@cometchat-pro/chat';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    NgIf,
    MatIconModule,
    MatDividerModule,
    RouterLink,
  ],
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @ViewChild('darkMode') darkMode;
  @Input() showAvatar: boolean = true;
  user: User;
  scheme: 'dark' | 'light';
  isDarkMode: boolean = false;
  isLoggedInAs: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService,
    private _fuseConfigService: FuseConfigService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this.user = user;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this._fuseConfigService.config$.subscribe((res) => {
      this.scheme = res.scheme;
    });

    this.isLoggedInAs = localStorage.getItem('_loggedInAs') !== null;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  goToProfile(): void {
    this._router.navigate(['/profile']);
  }

  /**
   * Sign out
   */
  signOut(): void {
    CometChat.logout().then();
    sessionStorage.removeItem('menuAppearance');
    this._router.navigate(['/sign-out']).then();
  }

  /**
   * Sign out from login as page
   */
  goBack(): void {
    this._authService.goBack();
  }

  setTheme(theme: string): void {
    if (theme === 'dark') {
      localStorage.setItem('theme', 'dark');
      localStorage.setItem('theme-palette', 'theme-brand');
    } else {
      localStorage.setItem('theme', 'light');
      localStorage.setItem('theme-palette', 'theme-default');
    }
    this._fuseConfigService.config = {
      scheme: localStorage.getItem('theme'),
      theme: localStorage.getItem('theme-palette'),
    };
    this._changeDetectorRef.markForCheck();
  }

  getInitials(name: string): string {
    return name
      ?.match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }
}
