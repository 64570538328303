import { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  sentryEnabled: false,
  environmentTag: 'develop',
  graphqlUrl: 'https://dev.api.yload.eu/graphql',
  // graphqlUrl: 'http://localhost:6010/graphql',
  // apiUrl: 'http://localhost:6010/api/v1',
  apiUrl: 'https://dev.api.yload.eu/api/v1',
  // workloadApiUrl: 'https://dev.work.yload.eu/api/v1',
  workloadApiUrl: 'http://localhost:3000/api/v1',
  hereMapsApiKey: 'U79RgLO3fX6C90KSYIBgr3tXV52OHiBu-Sk3ymbSxgo',
  appBaseUrl: 'https://dev.network.yload.eu',
  socketHost: 'dev.api.yload.eu',
  gaId: 'G-5H08BDB73G',
  uploadsUrl: 'https://api.yload.eu/uploads',
  comeChatAppId: '25892830a5bce6e1',
  comeChatRegion: 'eu',
  comeChatAuthKey: '82b6a979abbe580e61ecc94fa3035e3d67880a8c',
  cometChatRestApiKey: '8846348d92ff8fee75720b62b53f71491d69b211',
  mapboxToken:
    'pk.eyJ1Ijoid2JyMDkiLCJhIjoiY2xzazYwaW91MDA0MzJqbm81OXFndWkycyJ9.IIzZM3ojJiKfFcTED96Pmw',
  oneSignalAppId: '52338764-c9a9-456f-9dfb-9b2cc554eead',
  tenantFrachtRomaniaId: 'b56a257a-d027-4bd9-80be-1942d7face89',
  tenantFrachtProjectCargoId: '26a5df2c-c591-4dbb-b6c0-db250f8af991',
};
