import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DenseService {
    private isNewSocialCreatedSubject = new BehaviorSubject<boolean>(false);
    private isNewCompanyNewsCreatedSubject = new BehaviorSubject<boolean>(
        false,
    );
    private isMarketNewsCreatedSubject = new BehaviorSubject<boolean>(false);

    constructor() {}

    setIsNewSocialCreated(data: boolean): void {
        this.isNewSocialCreatedSubject.next(data);
    }

    getIsNewSocialCreated(): Observable<boolean> {
        return this.isNewSocialCreatedSubject.asObservable();
    }

    setIsNewCompanyNewsCreated(data: boolean): void {
        this.isNewCompanyNewsCreatedSubject.next(data);
    }

    getIsNewCompanyNewsCreated(): Observable<boolean> {
        return this.isNewCompanyNewsCreatedSubject.asObservable();
    }

    setIsMarketNewsCreatedSubject(data: boolean): void {
        this.isMarketNewsCreatedSubject.next(data);
    }

    getIsMarketNewsCreatedSubject(): Observable<boolean> {
        return this.isMarketNewsCreatedSubject.asObservable();
    }
}
