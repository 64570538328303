import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {
  solar_alarmOutline,
  solar_alignLeftOutline,
  solar_alignVerticalSpacingOutline,
  solar_altArrowLeftOutline,
  solar_bellOutline,
  solar_boardFill,
  solar_buildings2Outline,
  solar_buildings3Outline,
  solar_calendarDaysOutline,
  solar_calendarOutline,
  solar_caseRoundOutline,
  solar_chatLineOutline,
  solar_chatRoundCheckOutline,
  solar_chatRoundOutline,
  solar_checklistBrokenOutline,
  solar_checklistOutline,
  solar_checkSquareBrokenOutline,
  solar_clapperboardPlayOutline,
  solar_clipboardOutline,
  solar_clockCircleOutline,
  solar_copyOutline,
  solar_dangerSquareOutline,
  solar_documentAddOutline,
  solar_documentOutline,
  solar_dollarOutline,
  solar_downloadOutline,
  solar_emojiFunnySquareOutline,
  solar_filterOutline,
  solar_flagOutline,
  solar_folderOutline,
  solar_folderWithFilesMenuOutline,
  solar_folderWithFilesOutline,
  solar_galleryAddOutline,
  solar_galleryOutline,
  solar_globalOutline,
  solar_heartOutline,
  solar_historyOutline,
  solar_inboxLineOutline,
  solar_infoOutline,
  solar_layersMinimalisticOutline,
  solar_letterLinearOutline,
  solar_letterOutline,
  solar_letterUnreadOutline,
  solar_likeOutline,
  solar_linkMinimalistic2Outline,
  solar_linkMinimalisticOutline,
  solar_linkOutline,
  solar_listCheckMinimalisticOutline,
  solar_listCheckOutline,
  solar_listOutline,
  solar_logoWhatsappOutline,
  solar_mapPointOutline,
  solar_maximizeOutline,
  solar_maximizeSquare3Outline,
  solar_minimizeOutline,
  solar_minimizeSquare3Outline,
  solar_multipleForwardRightOutline,
  solar_notebookSquareMenuOutline,
  solar_paperclipOutline,
  solar_penNewSquareOutline,
  solar_penRoundOutline,
  solar_phoneOutline,
  solar_phoneRoundedOutline,
  solar_pinOutline,
  solar_plainOutline,
  solar_plateOutline,
  solar_plusSign,
  solar_refreshOutline,
  solar_reorderLinearOutline,
  solar_roundGraphOutline,
  solar_scaleOutline,
  solar_searchOutline,
  solar_shareOutline,
  solar_sortByTimeOutline,
  solar_sortVerticalOutline,
  solar_squareShareLineOutline,
  solar_tableOutline,
  solar_taskOutline,
  solar_textSquareOutline,
  solar_trafficOutline,
  solar_trashBin2Outline,
  solar_uploadMinimalisticOutline,
  solar_userCircleOutline,
  solar_userOutline,
  solar_userPlusBrokenOutline,
  solar_usersGroupRoundedOutline,
  solar_widgetAddOutline,
  solar_widgetOutline,
  solar_smartphone2Outline,
  solar_truckOutline,
  solar_airFreightOutline,
  solar_shipOutline,
  solar_craneOutline,
  solar_magnifierOutline,
  solar_sortOutline,
  solar_calendarDateOutline,
  solar_buildingsOutline,
  solar_checkSquareOutline,
  solar_calendarMinimalisticOutline,
  solar_boardOutline,
  solar_smartphoneOutline,
  solar_sortHorizontalOutline,
  solar_userCheckOutline,
  solar_checklistMinimalisticOutline,
  solar_hashtagSquareOutline,
  solar_fileTextOutline,
  solar_boxMinimalisticOutline,
  solar_listDownMinimalisticOutline,
  solar_notesOutline,
  solar_replyOutline,
  solar_fileOutline,
  solar_sliderMinimalisticHorizontalOutline,
  solar_mapOutline,
  solar_tagOutline,
  solar_lockPasswordOutline,
  solar_cameraOutline,
  solar_routingOutline,
  solar_cargoShipOutline,
  solar_airplaneOutline,
  solar_usersGroupTwoRoundedOutline,
  solar_airplaneTakeOffOutline,
  solar_hamburgerMenuOutline,
  solar_arrowUpOutline,
  solar_arrowDownOutline, solar_cardTransferOutline, solar_chartSquareOutline,
  solar_chartOutline
} from '../../../../public/icons/solar-outline';
import {
  solar_altArrowRightBold,
  solar_bellBold,
  solar_folderWithFileBold, solar_heartBold, solar_likeBold,
  solar_menuDotsBold, solar_pinBold,
} from '../../../../public/icons/solar-bold';

@Injectable({
  providedIn: 'root',
})

export class IconsService {

  constructor(
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
  ) {}

  initIcons(): void {
    this.iconRegistry.addSvgIconLiteral(
      'solar_letterLinearOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_letterLinearOutline),
    )
    this.iconRegistry.addSvgIconLiteral(
      'solar_calendarOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarOutline),
    )
    this.iconRegistry.addSvgIconLiteral(
      'solar_sortVerticalOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_sortVerticalOutline),
    )
    this.iconRegistry.addSvgIconLiteral(
      'solar_bellOutline',
      this.sanitizer.bypassSecurityTrustHtml(solar_bellOutline),
    );
    this.iconRegistry.addSvgIconLiteral(
      'solar_bellFilled',
      this.sanitizer.bypassSecurityTrustHtml(solar_bellBold),
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_buildings3Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_buildings3Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_buildings2Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_buildings2Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_userCircleOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_userCircleOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_userOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_userOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_mapPointOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_mapPointOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_caseRoundOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_caseRoundOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_linkMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_linkMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_letterOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_letterOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_phoneRoundedOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_phoneRoundedOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_smartphone2Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_smartphone2Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_clockCircleOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_clockCircleOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_clipboardOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_clipboardOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_plusSign",
      this.sanitizer.bypassSecurityTrustHtml(solar_plusSign)
    );
    let matIconRegistry = this.iconRegistry.addSvgIconLiteral(
      "solar_userPlusBrokenOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_userPlusBrokenOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_calendarOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_paperclipOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_paperclipOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_emojiFunnySquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_emojiFunnySquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_textSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_textSquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checklistBrokenOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checklistBrokenOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_calendarDaysOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarDaysOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_listCheckOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_listCheckOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checkSquareBrokenOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checkSquareBrokenOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checklistOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checklistOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_documentAddOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_documentAddOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_notesOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_notesOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_galleryAddOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_galleryAddOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_menuDotsBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_menuDotsBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_squareShareLineOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_squareShareLineOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_alarmOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_alarmOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_trashBin2Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_trashBin2Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_plainOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_plainOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_folderWithFilesMenuOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_folderWithFilesMenuOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_folderWithFilesOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_folderWithFilesOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_scaleOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_scaleOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_maximizeSquare3Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_maximizeSquare3Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_minimizeSquare3Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_minimizeSquare3Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_searchOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_searchOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_filterOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_filterOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_downloadOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_downloadOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_documentOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_documentOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_notebookSquareMenuOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_notebookSquareMenuOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_linkOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_linkOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_dangerSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_dangerSquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_chatLineOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_chatLineOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_reorderLinearOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_reorderLinearOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_boardFill",
      this.sanitizer.bypassSecurityTrustHtml(solar_boardFill)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_tableOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_tableOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_usersGroupRoundedOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_usersGroupRoundedOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_trafficOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_trafficOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_widgetAddOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_widgetAddOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_roundGraphOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_roundGraphOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_refreshOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_refreshOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_maximizeOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_maximizeOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_minimizeOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_minimizeOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_shareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_shareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_penRoundOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_penRoundOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_historyOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_historyOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_taskOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_taskOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_flagOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_flagOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_altArrowRightBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_altArrowRightBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_phoneOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_phoneOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_letterUnreadOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_letterUnreadOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_alignVerticalSpacingOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_alignVerticalSpacingOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_folderOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_folderOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_globalOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_globalOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_uploadMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_uploadMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_dollarOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_dollarOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_listCheckMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_listCheckMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_widgetOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_widgetOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_listOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_listOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_infoOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_infoOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_folderWithFileBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_folderWithFileBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_penNewSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_penNewSquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_galleryOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_galleryOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_likeBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_likeBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_likeOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_likeOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_pinBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_pinBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_pinOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_pinOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_linkMinimalistic2Outline",
      this.sanitizer.bypassSecurityTrustHtml(solar_linkMinimalistic2Outline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_chatRoundCheckOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_chatRoundCheckOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_chatRoundOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_chatRoundOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_heartBold",
      this.sanitizer.bypassSecurityTrustHtml(solar_heartBold)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_heartOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_heartOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_multipleForwardRightOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_multipleForwardRightOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_logoWhatsappOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_logoWhatsappOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_copyOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_copyOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_clapperboardPlayOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_clapperboardPlayOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_altArrowLeftOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_altArrowLeftOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_inboxLineOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_inboxLineOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_truckOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_truckOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_airFreightOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_airFreightOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_shipOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_shipOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_craneOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_craneOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_layersMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_layersMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_plateOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_plateOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_alignLeftOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_alignLeftOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_sortByTimeOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_sortByTimeOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_magnifierOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_magnifierOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_sortOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_sortOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_calendarDateOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarDateOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_buildingsOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_buildingsOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checkSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checkSquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_boardOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_boardOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_smartphoneOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_smartphoneOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_sortHorizontalOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_sortHorizontalOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_calendarMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_calendarMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_userCheckOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_userCheckOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_checklistMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_checklistMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_hashtagSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_hashtagSquareOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_fileTextOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_fileTextOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_boxMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_boxMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_listDownMinimalisticOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_listDownMinimalisticOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_replyOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_replyOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_fileOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_fileOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_sliderMinimalisticHorizontalOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_sliderMinimalisticHorizontalOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_mapOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_mapOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_tagOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_tagOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_lockPasswordOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_lockPasswordOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_cameraOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_cameraOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_routingOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_routingOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_cargoShipOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_cargoShipOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_airplaneOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_airplaneOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_usersGroupTwoRoundedOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_usersGroupTwoRoundedOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_airplaneTakeOffOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_airplaneTakeOffOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_hamburgerMenuOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_hamburgerMenuOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_arrowUpOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_arrowUpOutline)
    )
    this.iconRegistry.addSvgIconLiteral(
      "solar_arrowDownOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_arrowDownOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_chartOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_chartOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_cardTransferOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_cardTransferOutline)
    );
    this.iconRegistry.addSvgIconLiteral(
      "solar_chartSquareOutline",
      this.sanitizer.bypassSecurityTrustHtml(solar_chartSquareOutline)
    );
  }
}
