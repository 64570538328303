import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { UserService } from '../../../services/user/user.service';
import { AppModulesEnum } from '../../../types/user/user.types';

export const moduleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const requiredModule: AppModulesEnum = route.data?.['module'];

  const userService = inject(UserService);

  return userService.get().pipe(
    map((loggedUser) => {
      const userPermissions = loggedUser.permissions.find(
        (permission) => permission.module === requiredModule
      );
      return !!userPermissions;
    })
  );
};
