import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IconsService } from './services/icons/icons.service';
import { environment } from 'environments/environment';
import { CometChat } from '@cometchat-pro/chat';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet],
})
export class AppComponent {
  appID: string = environment.comeChatAppId;
  region: string = environment.comeChatRegion;
  appSetting: CometChat.AppSettings = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(this.region)
  .autoEstablishSocketConnection(true)
  .build();
    /**
     * Constructor
     */
    constructor(
      private iconsService: IconsService,
    ) {
      this.iconsService.initIcons();
    }

  
    ngOnInit(): void {
      CometChat.init(this.appID, this.appSetting).then(
        (initialized: boolean) => {
          console.log('Initialization completed successfully', initialized);
        },
        (error: CometChat.CometChatException) => {
          console.log('Initialization failed with error:', error);
        },
      );
    }
}
