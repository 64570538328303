import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { CometChat } from '@cometchat-pro/chat';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LoadingService } from './services/loading/loading.service';
import Hotjar from '@hotjar/browser';
import { LoadingComponent } from './components/loading/loading.component';
import { NgIf } from '@angular/common';
import { OneSignalService } from './services/one-signal/one-signal.service';
import { IconsService } from "./services/icons/icons.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, LoadingComponent, NgIf],
  providers: [],
})
export class AppComponent implements OnInit {
  appID: string = environment.comeChatAppId;
  region: string = environment.comeChatRegion;
  hotjarSiteId = environment.hotjarSiteId;
  hotjarVersion = environment.hotjarVersion;
  appSetting: CometChat.AppSettings = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(this.region)
    .autoEstablishSocketConnection(true)
    .build();

  /**
   * Constructor
   */
  constructor(
    private gtmService: GoogleTagManagerService,
    private iconsService: IconsService,
    public loadingService: LoadingService,
    private oneSignalService: OneSignalService
  ) {
    this.iconsService.initIcons();
  }

  ngOnInit(): void {
    const siteId = 3681772;
    const hotjarVersion = 6;
    this.gtmService.addGtmToDom();
    if (this.hotjarSiteId && this.hotjarVersion) {
      Hotjar.init(siteId, hotjarVersion);
    }
    CometChat.init(this.appID, this.appSetting).then(
      (initialized: boolean) => {
        console.log('Initialization completed successfully', initialized);
      },
      (error: CometChat.CometChatException) => {
        console.log('Initialization failed with error:', error);
      },
    );
  }
}
