import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    IsActiveMatchOptions,
    RouterLink,
    RouterLinkActive,
} from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import {
    FuseNavigationItem,
    FuseVerticalNavigationAppearance,
} from '@fuse/components/navigation/navigation.types';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../../../../../../app/types/user/user.types';
import { DenseService } from '../../../../../../app/services/layout/dense/dense.service';

@Component({
    selector: 'fuse-vertical-navigation-basic-item',
    templateUrl: './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        RouterLink,
        RouterLinkActive,
        MatTooltipModule,
        NgTemplateOutlet,
        MatIconModule,
    ],
})
export class FuseVerticalNavigationBasicItemComponent
    implements OnInit, OnDestroy
{
    @Input() item: FuseNavigationItem;
    @Input() name: string;
    @Input() theme: string;
    @Input() unreadMessages: number;
    @Input() loggedUser: User;
    @Input() noOfLoadRequests: number;
    @Input() appearance: FuseVerticalNavigationAppearance;

    isNewSocialCreated: boolean = false;
    isNewCompanyNewsCreated: boolean = false;
    isMarketNewsCreated: boolean = false;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private _denseService: DenseService,
    ) {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent =
            this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this._denseService.getIsNewSocialCreated().subscribe((data) => {
            this.isNewSocialCreated = data;
            this._changeDetectorRef.markForCheck();
        });
        this._denseService.getIsNewCompanyNewsCreated().subscribe((data) => {
            this.isNewCompanyNewsCreated = data;
            this._changeDetectorRef.markForCheck();
        });
        this._denseService.getIsMarketNewsCreatedSubject().subscribe((data) => {
            this.isMarketNewsCreated = data;
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onItemClick(): void {
        switch (this.item.id) {
            case 'companyNews':
                this._denseService.setIsNewCompanyNewsCreated(false);
                break;
            case 'news':
                this._denseService.setIsNewSocialCreated(false);
                break;
            default:
                break;
        }
    }
}
