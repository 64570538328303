<!-- Navigation -->
<fuse-vertical-navigation
  [appearance]="'compact'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.compact"
  [opened]="!isScreenSmall"
  [theme]="theme"
  class="dark bg-gray-900 print:hidden">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 pl-3 pr-2 mb-3">
      <img
        *ngIf="theme === 'dark'"
        alt="Logo image"
        class="logo w-full max-w-26"
        height="100"
        ngSrc="assets/app-logo/app-logo_darkT.svg"
        priority="true"
        width="26"
      />
      <img
        *ngIf="theme !== 'dark'"
        alt="Logo image"
        class="logo w-full max-w-26"
        height="100"
        ngSrc="assets/app-logo/app-logo_lightT.svg"
        priority="true"
        width="26"
      />
    </div>

    <div
      [routerLink]="'/account'"
      class="flex flex-col items-center w-full px-4 pb-3 cursor-pointer mb-4"
    >
      <div
        class="relative w-14 h-14 user-profile-avatar-wrapper">
        <img
          *ngIf="loggedUser.profilePhotoUrl"
          [src]="loggedUser.profilePhotoUrl"
          alt="Avatar"
          class="w-full h-full rounded-full object-cover border border-warmGray-700 dark:border-gray-600"
        />
        <mat-icon
          *ngIf="!loggedUser.profilePhotoUrl"
          [svgIcon]="'heroicons_solid:user-circle'"
          class="user-profile-icon icon-size-14 user-profile-icon-wrapper"
        ></mat-icon>
      </div>
      <div class="flex flex-col items-center justify-center w-full mt-2">
        <div
          class="w-full whitespace-pre-wrap truncate text-ellipsis overflow-hidden text-center text-xs leading-normal font-medium user-profile-name"
        >
          {{ loggedUser.name }}
        </div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button
      (click)="toggleNavigation('mainNavigation')"
      *ngIf="isScreenSmall"
      mat-icon-button>
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <search [appearance]="'bar'"></search>
      <notifications></notifications>
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
         Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

</div>
