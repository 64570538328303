import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppModulesEnum,
  CrmServicesEnum, IUserPreference,
  RoleAccessLevels,
  User,
  UserRolesEnum, WorkloadServicesEnum,
  WorkloadUserRolesEnum
} from "app/types/user/user.types";
import {catchError, combineLatest, map, Observable, of, ReplaySubject, take} from 'rxjs';
import {
  QUERY_GET_SOCIAL_TERMS,
  QUERY_GET_USER_DATA,
} from '../../core/graphql/queries';
import { Apollo } from 'apollo-angular';
import {
  MUTATION_ACCEPT_SOCIAL_TERMS,
  MUTATION_SET_BIRTHDAY,
} from '../../core/graphql/mutations';
import {environment} from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _apollo: Apollo,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this._user.asObservable();
  }

  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }

  getUserDataById(userId: string): Observable<User> {
    return this._apollo
      .query({
        query: QUERY_GET_USER_DATA,
        variables: {
          userId,
        },
      })
      .pipe(map((result: any) => result.data.getUserData));
    // this._apollo
    //   .query({
    //     query: QUERY_GET_USER_DATA,
    //     variables: {
    //       userId: response.data.login.userId,
    //     },
    //   })
    //   .subscribe((user: any) => {
    //     this._userService.user = user.data.getUserData;
    //     this._authenticated = true;
    //   });
  }

  setBirthday(
    frachtBranchMemberId: string,
    birthday: string,
  ): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_SET_BIRTHDAY,
        variables: {
          frachtBranchMemberId,
          birthday,
        },
      })
      .pipe(map((result: any) => result.data.setBirthday));
  }

  acceptSocialTerms(): Observable<boolean> {
    return this._apollo
      .mutate({
        mutation: MUTATION_ACCEPT_SOCIAL_TERMS,
      })
      .pipe(map((result: any) => result.data.acceptSocialTerms));
  }

  getSocialTerms(): Observable<any> {
    return this._apollo
      .query({
        query: QUERY_GET_SOCIAL_TERMS,
      })
      .pipe(map((result: any) => result.data.getSocialTerms));
  }

  getWorkloadUsers(): Observable<any> {
    return this._httpClient.get(`${environment.workloadApiUrl}/identity/users`);
  }

  getUserPreferences(module: AppModulesEnum, service: WorkloadServicesEnum): Observable<any> {
    return this._httpClient.get(`${environment.workloadApiUrl}/identity/preferences?filters={"service":{"eq":"${module}_${service}"}}`);
  }

  createUserPreference(preference: IUserPreference): Observable<IUserPreference> {
    return this._httpClient.post<IUserPreference>(`${environment.workloadApiUrl}/identity/preferences`, preference);
  }

  updateUserPreference(id: string, preference: IUserPreference): Observable<IUserPreference> {
    return this._httpClient.put<IUserPreference>(`${environment.workloadApiUrl}/identity/preferences/${id}`, preference);
  }

  deletePreference(id: string): Observable<any> {
    return this._httpClient.delete(`${environment.workloadApiUrl}/identity/preferences/${id}`);
  }

  getExecutionCtx(): UserService {
    return this;
  }

  hasAccess(module?: AppModulesEnum, minimumRole?: UserRolesEnum): Observable<boolean> {
    return this.user$.pipe(
      map((user) => {
        const moduleAccess = !module || user.permissions.some(permission => permission.module === module);
        const userRole = module
          ? user.permissions.find(permission => permission.module === module)?.role
          : undefined;
        const roleAccess = !minimumRole || (userRole && RoleAccessLevels[userRole] >= RoleAccessLevels[minimumRole]);
        return moduleAccess && roleAccess;
      }),
    );
  }
}
