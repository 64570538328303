import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { AppModulesEnum } from '../types/user/user.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const moduleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const requiredModule: AppModulesEnum = route.data?.['module'];

  const userService = inject(UserService);

  return userService.get().pipe(
    map((loggedUser) => {
      const userPermissions = loggedUser.permissions.roles.find(permission => permission.module === requiredModule);
      return !!userPermissions;
    })
  );
};

