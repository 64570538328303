<!-- Loading bar -->
<!--<fuse-loading-bar></fuse-loading-bar>-->

<div class="flex flex-auto justify-center w-full sm:p-4 md:p-8 layout-centered">

    <!-- Navigation -->
<!--    <ng-container *ngIf="isScreenSmall">-->
<!--        <fuse-vertical-navigation-->
<!--            class="dark bg-gray-900 print:hidden"-->
<!--            [mode]="'over'"-->
<!--            [name]="'mainNavigation'"-->
<!--            [navigation]="navigation.default"-->
<!--            [opened]="false">-->
<!--            &lt;!&ndash; Navigation header hook &ndash;&gt;-->
<!--            <ng-container fuseVerticalNavigationContentHeader>-->
<!--                &lt;!&ndash; Logo &ndash;&gt;-->
<!--                <div class="flex items-center h-20 pt-6 px-8">-->
<!--                    <img-->
<!--                        class="w-30"-->
<!--                        src="assets/images/logo/logo-text-on-dark.svg">-->
<!--                </div>-->
<!--            </ng-container>-->
<!--        </fuse-vertical-navigation>-->
<!--    </ng-container>-->

    <!-- Wrapper -->
    <div class="flex flex-col items-center flex-auto min-w-0 max-w-360 overflow-hidden centered-wrapper">

        <!-- Header -->
<!--        <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden">-->
<!--            <ng-container *ngIf="!isScreenSmall">-->
<!--                &lt;!&ndash; Logo &ndash;&gt;-->
<!--                <div class="flex items-center mx-2 lg:mr-8">-->
<!--                    <div class="hidden lg:flex">-->
<!--                        &lt;!&ndash; Light version &ndash;&gt;-->
<!--                        <img-->
<!--                            class="dark:hidden w-24"-->
<!--                            src="assets/images/logo/logo-text.svg"-->
<!--                            alt="Logo image">-->
<!--                        &lt;!&ndash; Dark version &ndash;&gt;-->
<!--                        <img-->
<!--                            class="hidden dark:flex w-24"-->
<!--                            src="assets/images/logo/logo-text-on-dark.svg"-->
<!--                            alt="Logo image">-->
<!--                    </div>-->
<!--                    &lt;!&ndash; Small version &ndash;&gt;-->
<!--                    <img-->
<!--                        class="flex lg:hidden w-8"-->
<!--                        src="assets/images/logo/logo.svg"-->
<!--                        alt="Logo image">-->
<!--                </div>-->
<!--                &lt;!&ndash; Horizontal navigation &ndash;&gt;-->
<!--                <fuse-horizontal-navigation-->
<!--                    class="mr-2"-->
<!--                    [name]="'mainNavigation'"-->
<!--                    [navigation]="navigation.horizontal"></fuse-horizontal-navigation>-->
<!--            </ng-container>-->
<!--            &lt;!&ndash; Navigation toggle button &ndash;&gt;-->
<!--            <ng-container *ngIf="isScreenSmall">-->
<!--                <button-->
<!--                    class="mr-2"-->
<!--                    mat-icon-button-->
<!--                    (click)="toggleNavigation('mainNavigation')">-->
<!--                    <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>-->
<!--                </button>-->
<!--            </ng-container>-->
<!--        </div>-->

        <!-- Content -->
        <div class="flex flex-col flex-auto w-full">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>

        <!-- Footer -->
<!--        <div class="relative flex flex-0 items-center justify-start w-full h-16 sm:h-20 px-6 sm:px-8 z-49 bg-card border-t dark:bg-default print:hidden">-->
<!--            <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>-->
<!--        </div>-->

    </div>

</div>
