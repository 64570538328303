import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | string | number | null | undefined): string {
    if (!value) return 'Unknown time';

    let creationTime: DateTime;

    try {
      // Handle number (milliseconds timestamp)
      if (typeof value === 'number') {
        creationTime = DateTime.fromMillis(value, { zone: 'utc' });
      }
      // Handle Date object
      else if (value instanceof Date) {
        creationTime = DateTime.fromJSDate(value, { zone: 'utc' });
      }
      // Handle ISO string correctly
      else if (typeof value === 'string') {
        if (!isNaN(Number(value)) && value.trim().length >= 10 && value.trim().length <= 13) {
          // Handle numeric timestamp passed as string (e.g., "1700000000000")
          creationTime = DateTime.fromMillis(Number(value), { zone: 'utc' });
        } else {
          // Handle standard ISO string format
          creationTime = DateTime.fromISO(value, { zone: 'utc' });
        }
      } else {
        throw new Error('Invalid value type');
      }
    } catch (error) {
      console.error('Error parsing time value:', value, error);
      return 'Invalid date';
    }

    // Check if creationTime is valid
    if (!creationTime.isValid) {
      console.error('Parsed DateTime is invalid:', creationTime);
      return 'Invalid date';
    }

    const now = DateTime.now().setZone('utc');

    // Calculate differences
    const diffYears = now.year - creationTime.year;
    const diffMonths = now.month - creationTime.month + diffYears * 12;
    const diffDays = now.diff(creationTime, 'days').days;
    const diffHours = now.diff(creationTime, 'hours').hours;
    const diffMinutes = now.diff(creationTime, 'minutes').minutes;

    if (diffMonths >= 12) {
      const years = Math.floor(diffMonths / 12);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
    if (diffMonths >= 1) {
      return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
    }
    if (diffDays >= 1) {
      return `${Math.floor(diffDays)} day${diffDays > 1 ? 's' : ''} ago`;
    }
    if (diffHours >= 1) {
      return `${Math.floor(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`;
    }
    if (diffMinutes >= 1) {
      return `${Math.floor(diffMinutes)} minute${diffMinutes > 1 ? 's' : ''} ago`;
    }

    return 'Just now';
  }
}
