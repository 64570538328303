<!-- Item wrapper -->
<div
        (click)="onItemClick()"
        [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
        [ngClass]="{'': item.classes?.wrapper,
  'hidden': loggedUser?.role !== 'admin' && ((item.id === 'clients' && loggedUser?.role !== 'admin'))}"
        class="fuse-vertical-navigation-item-wrapper fuse-vertical-navigation-item-basic">

    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
        <a
                [fragment]="item.fragment ?? null"
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
                [preserveFragment]="item.preserveFragment ?? false"
                [queryParamsHandling]="item.queryParamsHandling ?? null"
                [queryParams]="item.queryParams ?? null"
                [routerLinkActiveOptions]="isActiveMatchOptions"
                [routerLinkActive]="'fuse-vertical-navigation-item-active'"
                [routerLink]="[item.link]"
                class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with an external link -->
    <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
        <a
                [href]="item.link"
                [matTooltip]="item.tooltip || ''"
                [target]="item.target || '_self'"
                class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a function -->
    <ng-container *ngIf="!item.link && item.function && !item.disabled">
        <div
                (click)="item.function(item)"
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
                class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an internal link and function -->
    <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
        <a
                (click)="item.function(item)"
                [fragment]="item.fragment ?? null"
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
                [preserveFragment]="item.preserveFragment ?? false"
                [queryParamsHandling]="item.queryParamsHandling ?? null"
                [queryParams]="item.queryParams ?? null"
                [routerLinkActiveOptions]="isActiveMatchOptions"
                [routerLinkActive]="'fuse-vertical-navigation-item-active'"
                [routerLink]="[item.link]"
                class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with an external link and function -->
    <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
        <a
                (click)="item.function(item)"
                [href]="item.link"
                [matTooltip]="item.tooltip || ''"
                [target]="item.target || '_self'"
                class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a no link and no function -->
    <ng-container *ngIf="!item.link && !item.function && !item.disabled">
        <div
                [matTooltip]="item.tooltip || ''"
                [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
                class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item is disabled -->
    <ng-container *ngIf="item.disabled">
        <div
                [matTooltip]="item.tooltip || ''"
                class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <ng-container *ngIf="item.icon">
        <ng-container *ngIf="item.id !== 'customers' || loggedUser?.role === 'admin'">
            <mat-icon [svgIcon]="item.icon" [class]="'mr-3 icon_theme_color icon_' + item.id"></mat-icon>
        </ng-container>
    </ng-container>

    <!-- Title & Subtitle -->
    <div
            [ngClass]="{'hidden': item.id === 'clients' && loggedUser?.role !== 'admin'}"
            class="fuse-vertical-navigation-item-title-wrapper">
        <div class="fuse-vertical-navigation-item-title item-title">
            <span [ngClass]="item.classes?.title">
                {{item.title}}
            </span>
        </div>
        <ng-container *ngIf="item.subtitle">
            <div class="fuse-vertical-navigation-item-subtitle">
                <span [ngClass]="item.classes?.subtitle">
                    {{item.subtitle}}
                </span>
            </div>
        </ng-container>
    </div>

    <!-- Badge -->
<!--    <ng-container *ngIf="item.badge">-->
<!--        <div class="fuse-vertical-navigation-item-badge">-->
<!--            <div-->
<!--                    [ngClass]="item.badge.classes"-->
<!--                    class="fuse-vertical-navigation-item-badge-content">-->
<!--                {{item.badge.title}}-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-container>-->

    <!-- Badge loads-->
    <ng-container *ngIf="item.id === 'cargo' && noOfLoadRequests > 0">
        <div class="fuse-vertical-navigation-item-badge">
            <div
                    class="bg-red-600 text-white rounded-full w-4 h-4 text-xs text-center flex justify-center items-center">
                {{ noOfLoadRequests }}
            </div>
        </div>
    </ng-container>

    <!-- Badge chat-->
    <ng-container *ngIf="item.id === 'chat' && unreadMessages > 0">
        <div class="fuse-vertical-navigation-item-badge">
            <div
                    class="bg-red-600 text-white rounded-full w-4 h-4 text-xs text-center flex justify-center items-center">
                {{ unreadMessages }}
            </div>
        </div>
    </ng-container>

    <!-- Badge news -->
    <ng-container *ngIf="item.id === 'news' && (isNewSocialCreated || isMarketNewsCreated)">
        <div class="fuse-vertical-navigation-item-badge">
            <div
                    class="bg-red-600 text-white rounded-full w-4 h-4 text-xs text-center flex justify-center items-center">
                !
            </div>
        </div>
    </ng-container>

    <!-- Badge company news -->
    <ng-container *ngIf="item.id === 'companyNews' && isNewCompanyNewsCreated">
        <div class="fuse-vertical-navigation-item-badge">
            <div
                    class="bg-red-600 text-white rounded-full w-4 h-4 text-xs text-center flex justify-center items-center">
                !
            </div>
        </div>
    </ng-container>

</ng-template>
