<!-- Loading bar -->
<!--<fuse-loading-bar></fuse-loading-bar>-->

<!-- Navigation -->
<fuse-vertical-navigation
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
  [theme]="theme"
  class="dark bg-app print:hidden"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 pl-3 pr-2 mb-3">
      <img
        *ngIf="theme === 'dark'"
        alt="Logo image"
        class="logo w-full max-w-26"
        height="100"
        ngSrc="assets/app-logo/app-logo_darkT.svg"
        priority="true"
        width="26"
      />
      <img
        *ngIf="theme !== 'dark'"
        alt="Logo image"
        class="logo w-full max-w-26"
        height="100"
        ngSrc="assets/app-logo/app-logo_lightT.svg"
        priority="true"
        width="26"
      />
    </div>

    <div
      [routerLink]="'/account'"
      class="flex flex-col items-center w-full px-4 pb-3 cursor-pointer"
    >
      <div
        class="relative"
        [ngClass]="{
          'w-24 h-24': navigationAppearance === 'default',
          'w-14 h-14 user-profile-avatar-wrapper':
            navigationAppearance === 'dense'
        }"
      >
        <img
          class="w-full h-full rounded-full object-cover border border-warmGray-700 dark:border-gray-600"
          *ngIf="loggedUser.profilePhotoUrl"
          [src]="loggedUser.profilePhotoUrl"
          alt="Avatar"
        />
        <mat-icon
          [ngClass]="{
            'icon-size-24': navigationAppearance === 'default',
            'icon-size-14 user-profile-icon-wrapper':
              navigationAppearance === 'dense'
          }"
          *ngIf="!loggedUser.profilePhotoUrl"
          class="user-profile-icon"
          [svgIcon]="'heroicons_solid:user-circle'"
        ></mat-icon>
      </div>
      <div class="flex flex-col items-center justify-center w-full mt-4">
        <div
          class="w-full whitespace-pre-wrap truncate text-ellipsis overflow-hidden text-center leading-normal font-medium user-profile-name"
          [ngClass]="{ 'text-xs ': navigationAppearance === 'dense' }"
        >
          {{ loggedUser.name }}
        </div>
        <div
          [ngClass]="{ hidden: navigationAppearance === 'dense' }"
          class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-navbar user-profile-email"
        >
          {{ loggedUser.email }}
        </div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <div class="flex items-center pr-2 space-x-2">
      <!-- Navigation toggle button -->
      <button (click)="toggleNavigation('mainNavigation')" mat-icon-button>
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
      </button>
      <!-- Navigation appearance toggle button -->
      <button
        (click)="toggleNavigationAppearance()"
        class="hidden md:inline-flex"
        mat-icon-button
      >
        <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
      </button>
    </div>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <notifications></notifications>
      <!--            <search [appearance]="'bar'"></search>-->
      <!--            <shortcuts></shortcuts>-->
      <!--            <messages></messages>-->
      <!--            <notifications></notifications>-->
      <!--            <button [routerLink]="'/chat'" class="relative flex justify-center">-->
      <!--                <mat-icon [svgIcon]="'feather:message-circle'"></mat-icon>-->
      <!--                <ng-container *ngIf="unreadMessages > 0">-->
      <!--                    <div class="absolute -top-1 -right-1.5 fuse-vertical-navigation-item-badge">-->
      <!--                        <div-->
      <!--                            class="bg-red-600 text-white rounded-full w-4 h-4 text-xs text-center flex justify-center items-center">-->
      <!--                            {{ unreadMessages }}-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </ng-container>-->
      <!--            </button>-->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
<!--    <div class="notification-bar" *ngIf="displayOnesignalBar">-->
<!--      <span class="icon">💡</span>-->
<!--      <span class="message">Notifications are not enabled on this browser.</span>-->
<!--      <a class="enable-link" (click)="displayOnesignalPrompt()">Click Here</a> to enable notifications.-->
<!--  </div>-->
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!--    &lt;!&ndash; Footer &ndash;&gt;-->
  <!--    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">-->
  <!--        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>-->
  <!--    </div>-->
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
