<!-- Notifications toggle -->
<button
  #notificationsOrigin
  (click)="openPanel()"
  mat-icon-button>
  <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
              class="flex items-center justify-center z-10 shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-600 text-red-50 text-xs font-medium">
                {{ unreadCount }}
            </span>
        </span>
  </ng-container>
  <mat-icon *ngIf="!panelOpened" [svgIcon]="'solar_bellOutline'"></mat-icon>
  <mat-icon *ngIf="panelOpened" [svgIcon]="'solar_bellFilled'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

  <div
    class="static inset-auto flex flex-col min-w-screen w-screen sm:min-w-90 sm:w-90 overflow-hidden shadow-2xl sm:rounded-md notification-center bg-white pb-3">

    <!-- Header -->
    <div class="flex shrink-0 items-center pt-3 pb-2 pl-5 pr-4 bg-white dark:bg-networkDarkGrey-900">
      <div class="text-md-base font-semibold leading-10 flex flex-row items-center gap-2">
        <span>Unread notifications</span>
        <div
          class="flex h-5 w-5 min-w-5 min-h-5 text-sm rounded-full justify-center items-center bg-networkMediumBlue-100 text-networkMediumBlue-800">
          {{ unreadCount }}
        </div>
      </div>
      <div class="ml-auto">
        <button
          (click)="closePanel()"
          [matTooltip]="'Close'">
          <mat-icon
            [svgIcon]="'heroicons_solid:x-mark'"
            class="icon-size-5 text-networkDarkGrey-400"></mat-icon>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div
      class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto bg-white dark:bg-networkDarkGrey-900 pb-3 overflow-x-hidden">
      <!-- Notifications -->
      <ng-container *ngFor="let notification of notifications; trackBy: trackByFn">
        <div
          [ngClass]="{'unread': !notification.read}"
          class="flex group relative hover:bg-networkLightGrey-100 dark:hover:bg-networkDarkGrey-700 notification-center__row border-b border-networkLightGrey-300 dark:border-networkDarkGrey-600">

          <!-- Notification with a link -->
          <ng-container *ngIf="notification.url">
            <!-- Router links -->
            <ng-container *ngIf="notification.url">
              <a
                (click)="redirectTo(notification)"
                class="flex flex-auto py-3 mx-3 items-center px-1 pr-8 cursor-pointer notification-center__row-item">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            </ng-container>
          </ng-container>

          <!-- Notification without a link -->
          <ng-container *ngIf="!notification.url">
            <div
              class="flex flex-auto py-2 mx-3 px-1 pr-8 items-center notification-center__row-item">
              <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </div>
          </ng-container>

          <!-- Actions -->
          <div class="absolute top-2.5 right-2">
            <!-- Remove -->
            <button
              class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
              mat-icon-button
              (click)="delete(notification)"
              [matTooltip]="'Remove'">
              <mat-icon
                class="icon-size-4 text-networkDarkGrey-900"
                [svgIcon]="'heroicons_outline:trash'"></mat-icon>
            </button>
          </div>

        </div>

        <!-- Notification content template -->
        <ng-template #notificationContent>
          <!-- Image -->
          <ng-container *ngIf="notification.thumb">
            <div class="flex flex-row items-center gap-1.5">
              <img
                [alt]="'Notification thumb'"
                [src]="notification.thumb"
                class="shrink-0 w-9 h-9 min-w-9 min-h-9 mr-4 rounded-full overflow-hidden object-cover object-center">
            </div>
          </ng-container>
          <ng-container *ngIf="!notification.thumb">
            <div
              class="flex shrink-0 w-9 h-9 min-w-9 min-h-9 mr-4 rounded-full justify-center items-center bg-networkMediumBlue-100">
              <mat-icon [svgIcon]="'solar_letterUnreadOutline'" class="icon-size-5 icon-notification"></mat-icon>
            </div>
          </ng-container>
          <!-- Title, description & time -->
          <div class="flex flex-col flex-auto text-md">
            <div
              class="block gap-x-1">
              <ng-container *ngIf="notification.source">
                <span
                  class="font-medium ">
                  {{ notification.source }}
                </span>
              </ng-container>
              <ng-container *ngIf="notification.title">
                <span
                  class="font-medium ">
                  {{ notification.title }}
                </span>
              </ng-container>
              <ng-container *ngIf="notification.description">
                <span
                  class="font-light ">
                  {{ notification.description }}
                </span>
              </ng-container>
              <ng-container *ngIf="notification.type">
                <span
                  class="font-medium capitalize">
                  {{ notification.type }}
                </span>
              </ng-container>
            </div>

            <div class="mt-2 text-xs leading-none text-networkDarkGrey-400 font-normal">
              {{ notification.createdAt | timeAgo }} ago
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- No notifications -->
      <ng-container *ngIf="!notifications || !notifications.length">
        <div
          class="flex flex-col flex-auto items-center justify-center py-6 px-8 notification-center__missing-notifications">
          <div class="w-full max-w-60 h-full mb-8 text-base text-center text-networkDarkGrey-300">No recent
            notifications.
          </div>
        </div>
      </ng-container>

    </div>

  </div>

</ng-template>
