import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';
import { User } from '../../../types/user/user.types';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user/user.service';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const dialog: MatDialog = inject(MatDialog);
    const userService = inject(UserService);

    let loggedUser: User;
    const companyName: string = 'yload';

    // Check the authentication status
    return inject(AuthService)
        .check()
        .pipe(
            switchMap((authenticated) => {
                // If the user is not authenticated...
                if (!authenticated) {
                    // Redirect to the sign-in page with a redirectUrl param
                    const redirectURL =
                        state.url === '/sign-out'
                            ? ''
                            : `redirectURL=${state.url}`;
                    const urlTree = router.parseUrl(`sign-in?${redirectURL}`);

                    return of(urlTree);
                }

                userService.get().subscribe((user: User) => {
                    loggedUser = user;
                });

                // if (loggedUser?.active === 0 &&
                //     (redirectURL === '/truck-freight/order' || redirectURL === '/truck-freight/load' ||
                //         redirectURL === '/companies' || redirectURL === '/truck-freight/truck-status')) {
                //     this.dialog.open(InactiveUserPopupComponent);
                //     return of(false);
                // }

                // Allow the access
                return of(true);
            })
        );
};
