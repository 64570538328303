export interface Notification {
  id: string;
  title: string;
  description: string;
  url: string;
  thumb: string;
  type: string;
  read: boolean;
  source: string;
  targetUserId: string;
  createdAt: Date;
}

export enum NotificationTypeEnum {
  CRM = 'CRM',
}

