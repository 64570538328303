import { provideHttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig, ErrorHandler,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
} from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter, Router,
  withHashLocation,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { provideAuth } from './core/providers/auth.provider';
import { HttpLink } from 'apollo-angular/http';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { environment } from '../environments/environment';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';
import { Loader } from '@googlemaps/js-api-loader';
import {NGX_MAT_DATE_FORMATS, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { IMAGE_CONFIG } from "@angular/common";
import { GALLERY_CONFIG, GalleryConfig } from "ng-gallery";
import * as Sentry from '@sentry/angular';
import {ContentLoaderModule} from '@ngneat/content-loader';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: ApolloLink.from([httpLink.create({ uri: environment.graphqlUrl })]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  };
}

const config: SocketIoConfig = {
  url: `wss://${environment.socketHost}`, // socket server url;
  options: {
    path: '/chat',
  },
};

const sentryProviders = environment.sentryEnabled ? [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
] : [];

export const appConfig: ApplicationConfig = {
  providers: [
    ...sentryProviders,
    provideAnimations(),
    provideHttpClient(),
    provideRouter(
      appRoutes,
      withHashLocation(),
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    importProvidersFrom(ApolloModule),
    importProvidersFrom(CookieService),
    importProvidersFrom(MatNativeDateModule),
    importProvidersFrom(SocketIoModule.forRoot(config)),
    importProvidersFrom(ContentLoaderModule),
    importProvidersFrom(
      GoogleTagManagerModule.forRoot({ id: environment.gaId }),
    ),

    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
      // multi: true,
    },
    importProvidersFrom(
      NgxMapboxGLModule.withConfig({
        accessToken: environment.mapboxToken,
      }),
    ),

    // Material Date Adapter
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyAFaylOBsuhYPYw9YqWmhN370xTvc6DXYU',
        libraries: ['places'],
      }),
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 40,
        breakpoints: [380, 460, 600, 960, 1280, 1440, 1600, 1800]
      }
    },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: true,
        imageSize: 'cover'
      } as GalleryConfig
    },
    importProvidersFrom(NgxMatNativeDateModule),
    // Transloco Config
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'en',
            label: 'English',
          },
        ],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: true,
      },
      loader: TranslocoHttpLoader,
    }),
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      useFactory: () => {
        const translocoService = inject(TranslocoService);
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);

        return () => firstValueFrom(translocoService.load(defaultLang));
      },
      multi: true,
    },

    // Fuse
    provideAuth(),
    provideIcons(),
    provideFuse({
      mockApi: {
        delay: 0,
        services: mockApiServices,
      },
      fuse: {
        layout: 'compact',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme: 'theme-default',
        themes: [
          {
            id: 'theme-default',
            name: 'Default',
          },
          {
            id: 'theme-brand',
            name: 'Brand',
          },
          {
            id: 'theme-teal',
            name: 'Teal',
          },
          {
            id: 'theme-rose',
            name: 'Rose',
          },
          {
            id: 'theme-purple',
            name: 'Purple',
          },
          {
            id: 'theme-amber',
            name: 'Amber',
          },
        ],
      },
    }),
  ],
};
