<!-- Button -->
<button
    [matMenuTriggerFor]="userActions"
    class="user-button text-md flex flex-nowrap gap-1 items-center">
    <span class="relative">
        <img
            *ngIf="user.profilePhotoUrl"
            [src]="user.profilePhotoUrl"
            class="w-7 h-7 object-cover border border-accent-100 rounded-full">
        <span *ngIf="!user.profilePhotoUrl"
              class="w-7 h-7 flex items-center justify-center border border-accent-100 text-sm rounded-full uppercase bg-gray-300 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
        {{ getInitials(user.name) }}
      </span>
        <!--        <span-->
        <!--                class="absolute right-0 bottom-0 w-2 h-2 rounded-full"-->
        <!--                [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,-->
        <!--                          'bg-green-500': user.status === 'online',-->
        <!--                          'bg-amber-500': user.status === 'away',-->
        <!--                          'bg-red-500': user.status === 'busy',-->
        <!--                          'bg-gray-400': user.status === 'not-visible'}"-->
        <!--        ></span>-->
    </span>

    <mat-menu
        #userActions="matMenu"
        [xPosition]="'before'"
        class="fixed user-menu">
        <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button [routerLink]="'/account'" mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>Profile</span>
        </button>
        <button (click)="setTheme('dark');
                  $event.stopPropagation();
                  $event.preventDefault()"
                *ngIf="scheme === 'light'"
                mat-menu-item>
            <ng-container>
                <mat-icon [svgIcon]="'halfMoon'" class="icon-half-moon"></mat-icon>
                <span>Dark Mode</span>
            </ng-container>

        </button>
        <button (click)="setTheme('light');
                  $event.stopPropagation();
                  $event.preventDefault()"
                *ngIf="scheme === 'dark'"
                mat-menu-item>

            <ng-container>
                <mat-icon [svgIcon]="'sunLight'" class="icon-size-5 icon-sun-light"></mat-icon>
                <span>Light Mode</span>
            </ng-container>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button (click)="signOut()"
                *ngIf="!isLoggedInAs"
                mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>Sign out</span>
        </button>
        <button (click)="goBack()"
                *ngIf="isLoggedInAs"
                mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>Back</span>
        </button>
    </mat-menu>

    <span [matMenuTriggerFor]="userActions" class="relative text-gray-600 dark:text-gray-300"
          style="cursor: pointer;">
    <ng-container *ngIf="!isLoggedInAs">{{user.name}}</ng-container>
    <ng-container *ngIf="isLoggedInAs" class="flex flex-col">
      <span class="text-sm mb-1">Logged as:</span>
        {{user.name}}
    </ng-container>
  </span>
</button>
