import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from "@angular/router";
import { FuseFullscreenComponent } from "@fuse/components/fullscreen";
import { FuseLoadingBarComponent } from "@fuse/components/loading-bar";
import { FuseNavigationService, FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { NavigationService } from "app/core/navigation/navigation.service";
import { Navigation } from "app/core/navigation/navigation.types";
import { LanguagesComponent } from "app/layout/common/languages/languages.component";
import { MessagesComponent } from "app/layout/common/messages/messages.component";
import { NotificationsComponent } from "app/layout/common/notifications/notifications.component";
import { QuickChatComponent } from "app/layout/common/quick-chat/quick-chat.component";
import { SearchComponent } from "app/layout/common/search/search.component";
import { ShortcutsComponent } from "app/layout/common/shortcuts/shortcuts.component";
import { UserComponent } from "app/layout/common/user/user.component";
import { first, interval, Subject, switchMap, take, takeUntil } from "rxjs";
import { FuseConfig, FuseConfigService } from "../../../../../@fuse/services/config";
import { User } from "../../../../types/user/user.types";
import { UserService } from "../../../../services/user/user.service";
import { OneSignalService } from "../../../../services/one-signal/one-signal.service";
import { SocialService } from "../../../../services/social/social.service";
import { DenseService } from "../../../../services/layout/dense/dense.service";
import { ChatService } from "../../../../services/chat/chat.service";
import { MatDialog } from "@angular/material/dialog";
import { IChatRoom, IChatRoomJoin } from "../../../../types/chat/chat.types";
import { AcceptTcModalComponent } from "../../../../components/accept-tc-modal/accept-tc-modal.component";

@Component({
  selector: "compact-layout",
  templateUrl: "./compact.component.html",
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FuseLoadingBarComponent, MatButtonModule, MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, NotificationsComponent, UserComponent, NgIf, RouterOutlet, QuickChatComponent, FuseVerticalNavigationComponent, NgOptimizedImage, RouterLink, NgClass]
})
export class CompactLayoutComponent implements OnInit, OnDestroy, AfterContentChecked {
  config: FuseConfig;
  isScreenSmall: boolean;
  navigation: Navigation;
  unreadMessages: number = 0;
  theme: string;
  loggedUser: User;
  displayOnesignalBar: boolean = true;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _socialService: SocialService,
    private _denseService: DenseService,
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _chatService: ChatService,
    private _fuseConfigService: FuseConfigService,
    private _oneSignalService: OneSignalService,
    public dialog: MatDialog
  ) {
  }


  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
    this._userService.get().subscribe((user) => (this.loggedUser = user));

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes("md");
      });

    this._fuseConfigService.config = {
      scheme: localStorage.getItem('theme'),
      theme: localStorage.getItem('theme-palette'),
      appearance: sessionStorage.getItem('menuAppearance'),
    };

    this._fuseConfigService.config$.subscribe(
      (res) => (this.theme = res.scheme),
    );

    this._chatService.startChat();
    this._changeDetectorRef.markForCheck();
    this._chatService.chatRooms
      .pipe(take(1))
      .subscribe((result: IChatRoomJoin) => {
        result.chats.forEach((item: IChatRoom) => {
          this.unreadMessages += item.unreadMessages;
        });
        this._chatService.updateMessageCounter(this.unreadMessages);
        this._chatService.unreadMessages$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => (this.unreadMessages = res));
        this._chatService.updateMessageCounter(this.unreadMessages);
        this._changeDetectorRef.markForCheck();
      });
    this._chatService.onMessage
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (!result.error) {
          const audio = new Audio(
            '../../../../assets/sound-effects/chat_alert.mp3',
          );
          audio.play();
          this.unreadMessages++;
          this._chatService.updateMessageCounter(this.unreadMessages);
          this._chatService.unreadMessages$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
              this.unreadMessages = res;
            });
          this._chatService.updateMessageCounter(this.unreadMessages);
          this._changeDetectorRef.markForCheck();
        }
      });

    this.openTCModal();
    //Set up oneSignal subscription
    this._oneSignalService.addSubscriptionChangeEventListener(this.subscriptionChangeEventListener.bind(this));
    this.displayOnesignalBar = !this._oneSignalService.optedIn(); this._fuseConfigService.config = {
      scheme: localStorage.getItem('theme'),
      theme: localStorage.getItem('theme-palette'),
      appearance: sessionStorage.getItem('menuAppearance'),
    };

    this._fuseConfigService.config$.subscribe(
      (res) => (this.theme = res.scheme),
    );

    this._chatService.startChat();
    this._changeDetectorRef.markForCheck();
    this._chatService.chatRooms
      .pipe(take(1))
      .subscribe((result: IChatRoomJoin) => {
        result.chats.forEach((item: IChatRoom) => {
          this.unreadMessages += item.unreadMessages;
        });
        this._chatService.updateMessageCounter(this.unreadMessages);
        this._chatService.unreadMessages$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => (this.unreadMessages = res));
        this._chatService.updateMessageCounter(this.unreadMessages);
        this._changeDetectorRef.markForCheck();
      });
    this._chatService.onMessage
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (!result.error) {
          const audio = new Audio(
            '../../../../assets/sound-effects/chat_alert.mp3',
          );
          audio.play();
          this.unreadMessages++;
          this._chatService.updateMessageCounter(this.unreadMessages);
          this._chatService.unreadMessages$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
              this.unreadMessages = res;
            });
          this._chatService.updateMessageCounter(this.unreadMessages);
          this._changeDetectorRef.markForCheck();
        }
      });

    this.intervalGetLastNewsletterTimestamps(false);
    this.intervalGetLastNewsletterTimestamps(true);
    this.openTCModal();
    //Set up oneSignal subscription
    this._oneSignalService.addSubscriptionChangeEventListener(this.subscriptionChangeEventListener.bind(this));
    this.displayOnesignalBar = !this._oneSignalService.optedIn();
  }

  ngAfterContentChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._oneSignalService.removeSubscriptionChangeEventListener(this.subscriptionChangeEventListener);
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }


  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  intervalGetLastNewsletterTimestamps(isInterval = true): void {
    let func;
    if (isInterval) {
      func = interval(600000).pipe(
        switchMap(() => this._socialService.getLastNewsletterTimestamp()),
      );
    } else {
      func = this._socialService.getLastNewsletterTimestamp();
    }
    func?.subscribe((lastNewsletterTimestamps) => {
      for (const lastNewsletterTimestamp of lastNewsletterTimestamps) {
        switch (lastNewsletterTimestamp.newsletterType) {
          case 'social':
            this._denseService.setIsNewSocialCreated(
              lastNewsletterTimestamp.timestamp >
              this.loggedUser.lastSocialVisit,
            );
            break;
          case 'news':
            this._denseService.setIsMarketNewsCreatedSubject(
              lastNewsletterTimestamp.timestamp >
              this.loggedUser.lastMarketNewsVisit,
            );
            break;
          case 'company':
            this._denseService.setIsNewCompanyNewsCreated(
              lastNewsletterTimestamp.timestamp >
              this.loggedUser.lastCompanyNewsVisit,
            );
            break;
          default:
            break;
        }
      }
      this._changeDetectorRef.markForCheck();
    });
  }

  openTCModal(): void {
    this._userService
      .get()
      .pipe(first())
      .subscribe((user) => {
        if (user && !user.acceptedSocialTerms) {
          this._userService.getSocialTerms().subscribe((response) => {
            this.dialog.open(AcceptTcModalComponent, {
              disableClose: true,
              data: {
                type: 'warning',
                title: 'Terms and Conditions',
                content: response.replace(new RegExp('\n', 'g'), "<br />"),
              },
              panelClass: 'accept_tc_dialog',
              backdropClass: 'accept-tc-backdrop',
            });
          });
        }
      });
  }

  displayOnesignalPrompt(): void {
    this._oneSignalService.displayPrompt();
  }

  subscriptionChangeEventListener(event: { current: { optedIn: boolean }, previous: { optedIn: boolean } }) {
    if (event.current.optedIn){
      this.displayOnesignalBar = false;
    }
    else{
      this.displayOnesignalBar = true;
    }
  }
}
