import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IFilterConfig, IFilterEvent } from './filter.interface';

@Injectable({
  providedIn: 'root',
})
export class FilterQueryService {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   * Transforms query parameters to a nested filter structure.
   * @param params - The query parameters from the route.
   * @returns A decoded filters object where filter keys are mapped to their operator and value.
   */
  mapParamsToFilters(params: Params): Record<string, any> {
    const filtersParam = params['filters'];
    if (filtersParam) {
      try {
        return JSON.parse(filtersParam);
      } catch (error) {
        console.error('Invalid filters JSON format:', error);
      }
    }
    return {};
  }

  /**
   * Converts filters to both query parameters for navigation and HttpParams for HTTP calls.
   * @param event - The filter event containing the current filter state and any changes.
   * @returns An object containing both the Angular query parameters and the HTTP parameters.
   */
  mapFiltersToParams(event: IFilterEvent): {
    queryParams: Record<string, any>;
    httpParams: HttpParams;
  } {
    const filtersEmpty = !event.current || event.current.length === 0;

    const filters = filtersEmpty
      ? {}
      : this.encodeFiltersForQuery(event.current);
    const queryParams = filtersEmpty
      ? {}
      : { filters: JSON.stringify(filters) };
    const httpParams = filtersEmpty
      ? new HttpParams()
      : this.encodeFiltersForHttp(queryParams);

    return { queryParams, httpParams };
  }

  /**
   * Encodes filters for use as HTTP parameters.
   * @param filters - Encoded filters for the query parameters.
   * @returns HttpParams object for HTTP calls.
   */
  encodeFiltersForHttp(filters: Record<string, any>): HttpParams {
    let httpParams = new HttpParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        httpParams = httpParams.append(key, value);
      }
    });
    return httpParams;
  }

  /**
   * Updates the filter metadata with values from decoded filters.
   * @param filters - The array of filter metadata.
   * @param decodedFilters - The object containing filter keys and their values.
   * @returns The updated filter metadata with the applied values.
   */
  updateFilters(
    filters: IFilterConfig[],
    decodedFilters: Record<string, any>
  ): IFilterConfig[] {
    return filters.map((filter) => {
      const filterKey = filter.key;

      if (
        decodedFilters.hasOwnProperty(filterKey) &&
        decodedFilters[filterKey] !== null
      ) {
        filter.initialValue = decodedFilters[filterKey];
      } else {
        delete filter.initialValue;
      }

      return filter;
    });
  }

  /**
   * Encodes filters into the JSON format required by the backend.
   * @param filters - The array of filters to encode.
   * @returns The encoded filter conditions as an object for query parameters.
   */
  encodeFiltersForQuery(filters: any[]): Record<string, any> {
    const formattedFilters: Record<string, any> = {};
    filters.forEach((filter) => {
      const [key, value] = Object.entries(filter)[0];
      formattedFilters[key] = value;
    });
    return formattedFilters;
  }

  /**
   * Merges existing query parameters with new encoded filters, handling removed filters.
   * @param encodedFilters - Encoded filters to merge.
   * @param event - The filter event to handle removed filters.
   * @returns The combined query parameters.
   */
  private mergeQueryParams(
    encodedFilters: Record<string, any>,
    event: IFilterEvent
  ): Record<string, any> {
    const existingParams = { ...this.route.snapshot.queryParams };

    if (event.removed?.key) {
      const filters = this.mapParamsToFilters(existingParams);
      delete filters[event.removed.key];
      existingParams['filters'] = JSON.stringify(filters);
    }

    return { ...existingParams, filters: JSON.stringify(encodedFilters) };
  }

  public buildHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        const value = params[key];
        if (value !== null && value !== undefined) {
          if (typeof value === 'object') {
            httpParams = httpParams.set('filters', JSON.stringify(value));
          } else {
            httpParams = httpParams.set(key, value);
          }
        }
      });
    }

    return httpParams;
  }
}
