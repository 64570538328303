<div class="fuse-vertical-navigation-wrapper">
  <!-- Header -->
  <div class="fuse-vertical-navigation-header">
    <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
  </div>

  <!-- Content -->
  <div
    #navigationContent
    [fuseScrollbarOptions]="{ wheelPropagation: inner, suppressScrollX: true }"
    class="fuse-vertical-navigation-content"
    fuseScrollbar
  >
    <!-- Content header -->
    <div class="fuse-vertical-navigation-content-header">
      <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
    </div>

    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <ng-container >
        <!-- Aside -->
        <ng-container *ngIf="item.type === 'aside'">
          <fuse-vertical-navigation-aside-item
            (click)="toggleAside(item)"
            [activeItemId]="activeAsideItemId"
            [autoCollapse]="autoCollapse"
            [item]="item"
            [loggedUser]="loggedUser"
            [name]="name"
            [theme]="theme"
            [skipChildren]="true"
            [unreadMessages]="unreadMessages"
          ></fuse-vertical-navigation-aside-item>
        </ng-container>

        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
          <fuse-vertical-navigation-basic-item
            [item]="item"
            [loggedUser]="loggedUser"
            [name]="name"
            [noOfLoadRequests]="noOfLoadRequests"
            [theme]="theme"
            [unreadMessages]="unreadMessages"
          ></fuse-vertical-navigation-basic-item>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
          <fuse-vertical-navigation-collapsable-item
            [autoCollapse]="autoCollapse"
            [item]="item"
            [loggedUser]="loggedUser"
            [name]="name"
            [theme]="theme"
          ></fuse-vertical-navigation-collapsable-item>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="item.type === 'divider'">
          <fuse-vertical-navigation-divider-item
            [item]="item"
            [name]="name"
          ></fuse-vertical-navigation-divider-item>
        </ng-container>

        <!-- Group -->
        <ng-container>
          <fuse-vertical-navigation-group-item
            [autoCollapse]="autoCollapse"
            [item]="item"
            [loggedUser]="loggedUser"
            [name]="name"
          ></fuse-vertical-navigation-group-item>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
          <fuse-vertical-navigation-spacer-item
            [item]="item"
            [name]="name"
          ></fuse-vertical-navigation-spacer-item>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Content footer -->
    <!--        <div class="fuse-vertical-navigation-content-footer">-->
    <!--            <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>-->
    <!--        </div>-->
  </div>

  <!-- Content footer -->
  <div class="fuse-vertical-navigation-content-footer">
    <div class="terms-container flex flex-row gap-2.5 justify-center pt-0.5">
      <div class="font-thin text-center terms-text leading-4">
      <a
        class="text-xs"
        target="_blank"
        [href]="
          'https://fracht-social-images.s3.eu-central-1.amazonaws.com/Terms+of+Use+for+the+Fracht+Network+Communication+.pdf'
        "
        >Terms of Usage</a
      >
    </div>
      <div class="font-thin text-center mb-3 terms-text leading-4">
      <a
        class="text-xs"
        target="_blank"
        [href]="
          'https://fracht-social-images.s3.eu-central-1.amazonaws.com/Privacy+Policy+Fracht+Network+Communication.pdf'
        "
        >Privacy Policy</a
      >
    </div>
    </div>
    <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>
  </div>

  <!-- Footer -->
  <div class="fuse-vertical-navigation-footer">
    <div class="theme-switcher">
      <div
        (click)="
          setTheme('light'); $event.stopPropagation(); $event.preventDefault()
        "
        [ngClass]="{ 'switch-item-active': theme === 'light' }"
        class="switch-item light-item"
      >
        <div class="flex flex-row items-center">
          <img
            [src]="
              theme === 'light'
                ? '../../../assets/images/pages/menu-icons/SunLightActive.svg'
                : '../../../assets/images/pages/menu-icons/Sun-light.svg'
            "
          />
          <span class="ml-2">Light</span>
        </div>
      </div>
      <div
        (click)="
          setTheme('dark'); $event.stopPropagation(); $event.preventDefault()
        "
        [ngClass]="{ 'switch-item-active': theme === 'dark' }"
        class="switch-item dark-item"
      >
        <div class="flex flex-row items-center">
          <img
            [src]="
              theme === 'dark'
                ? '../../../assets/images/pages/menu-icons/HalfMoonActive.svg'
                : '../../../assets/images/pages/menu-icons/Half-moon.svg'
            "
          />
          <span class="ml-2">Dark</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
  <div
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'"
    [fuseScrollbarOptions]="{ wheelPropagation: false, suppressScrollX: true }"
    class="fuse-vertical-navigation-aside-wrapper"
    fuseScrollbar
  >
    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Aside -->
        <ng-container
          *ngIf="item.type === 'aside' && item.id === activeAsideItemId"
        >
          <fuse-vertical-navigation-aside-item
            [autoCollapse]="autoCollapse"
            [item]="item"
            [name]="name"
          ></fuse-vertical-navigation-aside-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
