<div class="flex flex-col tc-dialog-container">
  <h1 mat-dialog-title class="flex items-center gap-3">
    <ng-container *ngIf="data.type && data.type === 'warning'">
      <mat-icon
        svgIcon="heroicons_outline:document-check"
        class="icon-size-8 icon-warning"
      ></mat-icon>
    </ng-container>
    <span class="text-2xl">{{ data?.title }}</span>
  </h1>
  <div mat-dialog-content class="mt-5" [innerHtml] = data?.content></div>
  <div
    mat-dialog-actions
    class="flex flex-row gap-4 mt-5 border-t border-networkLightGrey-600"
  >
    <button
      (click)="signOut()"
      mat-button
      mat-dialog-close
      class="ml-auto dark:text-white mt-2"
    >
      Reject
    </button>
    <button
      mat-button
      class="bg-primary-500 text-white mt-2"
      (click)="acceptSocialTerms()"
    >
      Accept
    </button>
  </div>
</div>
