import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import * as Sentry from "@sentry/angular";
import {environment} from './environments/environment';

if (environment.sentryEnabled) {
  Sentry.init({
    environment: environment.environmentTag,
    dsn: "https://003261cd27f18df33c48087b15170d83@o4507333072846848.ingest.de.sentry.io/4507333096767568",
    integrations: [
      Sentry.replayIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));

(window as any).triggerRequestQuoteModal = (country?: string) => {
  const modalService = (window as any).modalServiceInstance;
  if (modalService) {
    modalService.openRequestQuoteModal(country);
  }
};

// dummy handler to fix issue with cdkDragAndDrop
document.addEventListener("mousemove", () => {}, { passive: false, capture: true });

