<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-white print:hidden"
    [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.compact"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="mb-4 flex h-20 items-center justify-center">
            <img [priority]="true" width="96" height="36" class="w-24" ngSrc="images/logo/app-logo_lightT.svg" alt="Logo image" />
        </div>

      <div
        [routerLink]="'/account'"
        class="flex flex-col items-center w-full px-4 pb-3 cursor-pointer mb-4"
      >
        <div
          class="relative w-14 h-14 user-profile-avatar-wrapper">
          <img
            *ngIf="loggedUser?.profilePhotoUrl"
            [src]="loggedUser?.profilePhotoUrl"
            alt="Avatar"
            class="w-full h-full rounded-full object-cover border border-warmGray-700 dark:border-gray-600"
          />
          <mat-icon
            *ngIf="!loggedUser?.profilePhotoUrl"
            [svgIcon]="'heroicons_solid:user-circle'"
            class="user-profile-icon icon-size-16 user-profile-icon-wrapper"
          ></mat-icon>
        </div>
        <div class="flex flex-col items-center justify-center w-full mt-2">
          <div
            class="w-full whitespace-pre-wrap truncate text-ellipsis overflow-hidden text-center text-xs leading-normal font-medium user-profile-name"
          >
            {{ loggedUser?.name }}
          </div>
        </div>
      </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
      <!-- Navigation toggle button -->
      <button *ngIf="isScreenSmall" mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
      </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <search [appearance]="'bar'"></search>
            <notifications></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>
</div>

