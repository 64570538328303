import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
  FuseNavigationService,
  FuseVerticalNavigationAppearance,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { first, interval, Subject, switchMap, take, takeUntil } from 'rxjs';
import { SocialService } from '../../../../services/social/social.service';
import { DenseService } from '../../../../services/layout/dense/dense.service';
import { User } from '../../../../types/user/user.types';
import { UserService } from '../../../../services/user/user.service';
import { ChatService } from 'app/services/chat/chat.service';
import { FuseConfig, FuseConfigService } from "../../../../../@fuse/services/config";
import { LoadRequestService } from '../../../../services/load/load-request.service';
import { IChatRoom, IChatRoomJoin } from '../../../../types/chat/chat.types';
import { QUERY_GET_USER_DATA } from '../../../../core/graphql/queries';
import { AcceptTcModalComponent } from '../../../../components/accept-tc-modal/accept-tc-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { OneSignalService } from 'app/services/one-signal/one-signal.service';

@Component({
  selector: 'dense-layout',
  templateUrl: './dense.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseLoadingBarComponent,
    FuseVerticalNavigationComponent,
    MatButtonModule,
    MatIconModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchComponent,
    ShortcutsComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    NgIf,
    RouterOutlet,
    QuickChatComponent,
    NgClass,
    RouterLink,
    NgOptimizedImage,
  ],
})
export class DenseLayoutComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  config: FuseConfig;
  isScreenSmall: boolean;
  navigation: Navigation;
  navigationAppearance: 'default' | 'dense' = 'dense';
  unreadMessages: number = 0;
  theme: string;
  noOfLoadRequests: number = 0;
  loggedUser: User;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  displayOnesignalBar: boolean = true;


  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _socialService: SocialService,
    private _denseService: DenseService,
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _chatService: ChatService,
    private _fuseConfigService: FuseConfigService,
    private _loadRequestService: LoadRequestService,
    private _oneSignalService: OneSignalService,
    public dialog: MatDialog,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._userService.get().subscribe((user) => (this.loggedUser = user));

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');

        // Change the navigation appearance
        this.navigationAppearance = this.isScreenSmall
          ? 'default'
          : sessionStorage.getItem('menuAppearance')
            ? (sessionStorage.getItem('menuAppearance') as 'default' | 'dense')
            : 'dense';

        if (this.isScreenSmall) {
          sessionStorage.setItem('menuAppearance', 'default');
        }
      });

    this._fuseConfigService.config = {
      scheme: localStorage.getItem('theme'),
      theme: localStorage.getItem('theme-palette'),
      appearance: sessionStorage.getItem('menuAppearance'),
    };

    this._fuseConfigService.config$.subscribe(
      (res) => (this.theme = res.scheme),
    );

    this._chatService.startChat();
    this._changeDetectorRef.markForCheck();
    this._chatService.chatRooms
      .pipe(take(1))
      .subscribe((result: IChatRoomJoin) => {
        result.chats.forEach((item: IChatRoom) => {
          this.unreadMessages += item.unreadMessages;
        });
        this._chatService.updateMessageCounter(this.unreadMessages);
        this._chatService.unreadMessages$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => (this.unreadMessages = res));
        this._chatService.updateMessageCounter(this.unreadMessages);
        this._changeDetectorRef.markForCheck();
      });
    this._chatService.onMessage
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (!result.error) {
          const audio = new Audio(
            '../../../../assets/sound-effects/chat_alert.mp3',
          );
          audio.play();
          this.unreadMessages++;
          this._chatService.updateMessageCounter(this.unreadMessages);
          this._chatService.unreadMessages$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
              this.unreadMessages = res;
            });
          this._chatService.updateMessageCounter(this.unreadMessages);
          this._changeDetectorRef.markForCheck();
        }
      });

    this._loadRequestService.getPendingLoadRequestsCount().subscribe((res) => {
      this.noOfLoadRequests = res;
      this._changeDetectorRef.markForCheck();
    });

    this.intervalGetLastNewsletterTimestamps(false);
    this.intervalGetLastNewsletterTimestamps(true);
    this.openTCModal();
    //Set up oneSignal subscription 
    this._oneSignalService.addSubscriptionChangeEventListener(this.subscriptionChangeEventListener.bind(this));
    this.displayOnesignalBar = !this._oneSignalService.optedIn();
  }

  ngAfterContentChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._oneSignalService.removeSubscriptionChangeEventListener(this.subscriptionChangeEventListener);
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name,
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * Toggle the navigation appearance
   */
  toggleNavigationAppearance(): void {
    this.navigationAppearance =
      this.navigationAppearance === 'default' ? 'dense' : 'default';
    sessionStorage.setItem('menuAppearance', this.navigationAppearance);

    this._fuseConfigService.config = {
      appearance:  this.navigationAppearance
    }
    this._changeDetectorRef.markForCheck();
  }

  intervalGetLastNewsletterTimestamps(isInterval = true): void {
    let func;
    if (isInterval) {
      func = interval(600000).pipe(
        switchMap(() => this._socialService.getLastNewsletterTimestamp()),
      );
    } else {
      func = this._socialService.getLastNewsletterTimestamp();
    }
    func?.subscribe((lastNewsletterTimestamps) => {
      for (const lastNewsletterTimestamp of lastNewsletterTimestamps) {
        switch (lastNewsletterTimestamp.newsletterType) {
          case 'social':
            this._denseService.setIsNewSocialCreated(
              lastNewsletterTimestamp.timestamp >
                this.loggedUser.lastSocialVisit,
            );
            break;
          case 'news':
            this._denseService.setIsMarketNewsCreatedSubject(
              lastNewsletterTimestamp.timestamp >
                this.loggedUser.lastMarketNewsVisit,
            );
            break;
          case 'company':
            this._denseService.setIsNewCompanyNewsCreated(
              lastNewsletterTimestamp.timestamp >
                this.loggedUser.lastCompanyNewsVisit,
            );
            break;
          default:
            break;
        }
      }
      this._changeDetectorRef.markForCheck();
    });
  }

  openTCModal(): void {
    this._userService
      .get()
      .pipe(first())
      .subscribe((user) => {
        if (user && !user.acceptedSocialTerms) {
          this._userService.getSocialTerms().subscribe((response) => {
            this.dialog.open(AcceptTcModalComponent, {
              disableClose: true,
              data: {
                type: 'warning',
                title: 'Terms and Conditions',
                content: response.replace(new RegExp('\n', 'g'), "<br />"),
              },
              panelClass: 'accept_tc_dialog',
              backdropClass: 'accept-tc-backdrop',
            });
          });
        }
      });
  }

  displayOnesignalPrompt(): void {
    this._oneSignalService.displayPrompt();
  }

  subscriptionChangeEventListener(event: { current: { optedIn: boolean }, previous: { optedIn: boolean } }) {
    if (event.current.optedIn){
      this.displayOnesignalBar = false;
    }
    else{
      this.displayOnesignalBar = true;
    }
  }
}
