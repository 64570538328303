import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
    MUTATION_ACCEPT_LOAD_REQUEST,
    MUTATION_REJECT_LOAD_REQUEST,
} from 'app/core/graphql/mutations';
import { Observable, map } from 'rxjs';
import { QUERY_GET_PENDING_LOAD_REQUESTS_COUNT } from '../../core/graphql/queries';

@Injectable({
    providedIn: 'root',
})
export class LoadRequestService {
    constructor(private _apollo: Apollo) {}

    acceptLoadRequest(loadId: string): Observable<any> {
        return this._apollo
            .mutate({
                mutation: MUTATION_ACCEPT_LOAD_REQUEST,
                variables: {
                    loadId: loadId,
                },
            })
            .pipe(map((result: any) => result.data.acceptLoadRequest));
    }

    rejectLoadRequest(loadId: string, rejectReason: string): Observable<any> {
        return this._apollo
            .mutate({
                mutation: MUTATION_REJECT_LOAD_REQUEST,
                variables: {
                    loadId: loadId,
                    rejectReason: rejectReason,
                },
            })
            .pipe(map((result: any) => result.data.rejectLoadRequest));
    }

    getPendingLoadRequestsCount(): Observable<any> {
        return this._apollo
            .query({
                query: QUERY_GET_PENDING_LOAD_REQUESTS_COUNT,
            })
            .pipe(
                map((result: any) => result.data.getPendingLoadRequestsCount),
            );
    }
}
