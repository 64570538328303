import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { OneSignal } from "onesignal-ngx";

@Injectable({ providedIn: 'root' })
export class OneSignalService {

  appId: string = environment.oneSignalAppId;

  constructor(
    private oneSignal: OneSignal
  ) {
    oneSignal.init({
      appId: this.appId,
    }).then(() => {
      oneSignal.Slidedown.promptPush({ force: true });
    });
  }

  async login(externalUserId: string) {
    await this.oneSignal.login(externalUserId);
  }

  async displayPrompt() {
    await this.oneSignal.Slidedown.promptPush({ force: true });
  }

  async logout() {
    await this.oneSignal.logout();
  }

  addSubscriptionChangeEventListener(listener: (change: any) => void) {
    this.oneSignal.User.PushSubscription.addEventListener("change", listener);
  }

  removeSubscriptionChangeEventListener(listener: (change: any) => void) {
    this.oneSignal.User.PushSubscription.removeEventListener("change", listener);
  }

  optedIn() {
    return this.oneSignal.User.PushSubscription.optedIn;
  }

}