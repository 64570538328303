import { inject, Injectable } from '@angular/core';
import { NavigationMockApi } from 'app/core/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';

@Injectable({ providedIn: 'root' })
export class MockApiService {
    navigationMockApi = inject(NavigationMockApi);
    notificationsMockApi = inject(NotificationsMockApi);
    searchMockApi = inject(SearchMockApi);
}
