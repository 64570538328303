import { gql } from '@apollo/client/core';
import {
  FRAGMENT_CHATS,
  FRAGMENT_CLIENT,
  FRAGMENT_CLIENTS_ADMIN,
  FRAGMENT_COMPANY,
  FRAGMENT_COMPANY_DATA_OUTPUT,
  FRAGMENT_COMPANY_RELATION,
  FRAGMENT_COMPLAINT_DOCUMENT,
  FRAGMENT_COMPLAINT_REPLY,
  FRAGMENT_CONTACT,
  FRAGMENT_CURRENCY,
  FRAGMENT_FINANCE,
  FRAGMENT_FRACHT_USERS,
  FRAGMENT_FRACHT_USER_TYPE,
  FRAGMENT_INVOICE_DATA_OUTPUT,
  FRAGMENT_LOAD_FIELDS,
  FRAGMENT_LOCATION_DETAILS,
  FRAGMENT_MY_LOADS_DATA_OUTPUT,
  FRAGMENT_ORDER_COMPANY_OUTPUT,
  FRAGMENT_ORDER_DATA_OUTPUT,
  FRAGMENT_ORDER_DOCUMENT,
  FRAGMENT_TARGET,
  FRAGMENT_TRUCK,
  FRAGMENT_TRUCK_PLANNING,
  FRAGMENT_COMPLAINT,
  FRAGMENT_FRACHT_AGENT_OFFICES,
  FRAGMENT_FRACHT_OFFICE_AGENT_CONTACTS_TYPE,
  FRAGMENT_FRACHT_AGENT_OFFICE_TYPE,
  FRAGMENT_NEWSLETTERS_TYPE,
  FRAGMENT_AIR_SEA_TRANSPORTER,
  FRAGMENT_AIR_TRANSPORT_ORDER,
  FRAGMENT_AIRLINE,
  FRAGMENT_BANKING_DETAIL,
  FRAGMENT_INVOICE,
  FRAGMENT_SHIPMENT,
  FRAGMENT_BILLING,
  FRAGMENT_CONTAINER,
  FRAGMENT_SHIPMENT_DOCUMENT,
  FRAGMENT_SHIPMENT_WITH_FINANCIAL_DATA_OUTPUT,
  FRAGMENT_SHIPMENT_HISTORY,
  FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE,
  FRAGMENT_FRACHT_OFFICE_AGENT_CONTACT_TYPE,
  FRAGMENT_NEWSLETTER_TYPE,
  FRAGMENT_LAST_NEWSLETTERS_TIMESTAMP_TYPE,
  FRAGMENT_FRACHT_MEMBERS,
  FRAGMENT_CONTENT_TAG_TYPE,
  FRAGMENT_SHIPMENT_FINANCIAL_CASH_FLOW_OUTPUT,
  FRAGMENT_SHIPMENT_WITH_CASH_FLOW_DATA_OUTPUT,
  FRAGMENT_DEAL,
  FRAGMENT_COUNTRY,
  FRAGMENT_DEPARTMENT,
  FRAGMENT_FINANCIAL_COST,
  FRAGMENT_FINANCIAL_PRESET_ACCOUNT,
  FRAGMENT_FINANCIAL_RECURRING_COST,
  FRAGMENT_FINANCIAL_COST_VALUE,
  FRAGMENT_FINANCIAL_BANK_ACCOUNT,
  FRAGMENT_FRACHT_BRANCH_TYPE,
} from "./fragments";

export const QUERY_GET_USER_DATA = gql`
  ${FRAGMENT_CLIENT}
  query getUserData($userId: ID) {
    getUserData(userId: $userId) {
      ...FragmentClient
    }
  }
`;

export const QUERY_GET_ALL_USERS = gql`
  ${FRAGMENT_CLIENTS_ADMIN}
  query getAllUsers($paginate: PaginateInput, $filter: AllUsersFilterInput) {
    getAllUsers(paginate: $paginate, filter: $filter) {
      ...FragmentClientsAdmin
    }
  }
`;

export const QUERY_GET_USERS_PARTIAL = gql`
  query getUsersPartial($paginate: PaginateInput, $filter: UserFilter) {
    getUsersPartial(paginate: $paginate, filter: $filter) {
      count
      users {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_OPEN_LOADS = gql`
  ${FRAGMENT_CLIENT}
  ${FRAGMENT_CHATS}
  ${FRAGMENT_CURRENCY}
  ${FRAGMENT_CONTACT}
  ${FRAGMENT_LOCATION_DETAILS}
  ${FRAGMENT_LOAD_FIELDS}
  query getOpenLoads($paginate: PaginateInput, $filter: LoadFilterInput) {
    getOpenLoads(paginate: $paginate, filter: $filter) {
      count
      loads {
        id
        shortId
        userId
        carrierId
        carrier {
          ...FragmentClient
        }
        shipperId
        shipper {
          ...FragmentClient
        }
        status
        createdAt
        attachments
        chatList {
          ...FragmentChats
        }
        pickUp {
          ...FragmentLoadLocationDetails
        }
        dropOff {
          ...FragmentLoadLocationDetails
        }
        distance
        load {
          ...FragmentLoadFields
        }
        otherDetails
        price
        currency {
          ...FragmentCurrency
        }
        paymentDelay
        unreadMessages
        openChats
        contactId
        contact {
          ...FragmentContact
        }
        truckId
        driverIds
        type
      }
    }
  }
`;

export const QUERY_GET_ALL_ORDERS = gql`
  ${FRAGMENT_ORDER_COMPANY_OUTPUT}
  ${FRAGMENT_LOAD_FIELDS}
  ${FRAGMENT_ORDER_DOCUMENT}
  ${FRAGMENT_MY_LOADS_DATA_OUTPUT}
  query getAllOrders($paginate: PaginateInput, $filter: OrderFilterInput) {
    getAllOrders(paginate: $paginate, filter: $filter) {
      count
      orders {
        id
        user {
          id
          name
          companyId
          email
        }
        orderNo
        transporter {
          ...FragmentOrderCompanyOutput
        }
        sender {
          ...FragmentOrderCompanyOutput
        }
        load {
          ...LoadDataFragment
        }
        costs {
          costWithoutVat
          costVatPercent
          costCurrency
          costWithVat
          costPayableUntil
        }
        budgetCosts {
          costWithoutVat
          costVatPercent
          costCurrency
          costWithVat
          costPayableUntil
        }
        vehicle {
          vehicleDetails
          vehiclePlate
          trailerPlate
        }
        specialInstructions
        termsConditions
        createdAt
        updatedAt
        documents {
          ...OrderDocumentFragment
        }
        status
      }
    }
  }
`;

export const QUERY_GET_MY_COMPLAINTS = gql`
  ${FRAGMENT_COMPLAINT_DOCUMENT}
  ${FRAGMENT_COMPLAINT_REPLY}
  query getMyComplaints(
    $paginate: PaginateInput
    $filter: MyComplaintFilterInput
  ) {
    getMyComplaints(paginate: $paginate, filter: $filter) {
      count
      complaints {
        id
        complainerEmail
        complainerCompanyName
        complainerCui
        complainerCompanyId
        targetEmail
        targetCompanyName
        targetCompanyCui
        targetCompanyId
        type
        documents {
          ...ComplaintDocumentFragment
        }
        replies {
          ...ComplaintReplyFragment
        }
        status
        resolution
        text
        acceptedTerms
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_GET_COMPLAINTS_BY_CUI = gql`
  ${FRAGMENT_COMPLAINT_DOCUMENT}
  ${FRAGMENT_COMPLAINT_REPLY}
  query getComplaintsByCui($cui: String!) {
    getComplaintsByCui(cui: $cui) {
      id
      complainerEmail
      complainerCompanyName
      complainerCui
      complainerCompanyId
      targetEmail
      targetCompanyName
      targetCompanyCui
      targetCompanyId
      type
      documents {
        ...ComplaintDocumentFragment
      }
      replies {
        ...ComplaintReplyFragment
      }
      status
      resolution
      text
      acceptedTerms
      createdAt
      updatedAt
    }
  }
`;

export const QUERY_GET_COMPLAINTS_AGAINST_ME = gql`
  ${FRAGMENT_COMPLAINT_DOCUMENT}
  ${FRAGMENT_COMPLAINT_REPLY}
  query getComplaintsAgainstMe(
    $paginate: PaginateInput
    $filter: MyComplaintFilterInput
  ) {
    getComplaintsAgainstMe(paginate: $paginate, filter: $filter) {
      count
      complaints {
        id
        complainerEmail
        complainerCompanyName
        complainerCui
        complainerCompanyId
        targetEmail
        targetCompanyName
        targetCompanyCui
        targetCompanyId
        type
        documents {
          ...ComplaintDocumentFragment
        }
        replies {
          ...ComplaintReplyFragment
        }
        status
        resolution
        text
        acceptedTerms
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_GET_LOCATION_PLANNING_FOR_TRUCK_IDS = gql`
  ${FRAGMENT_TRUCK_PLANNING}
  query getLocationPlanningForTruckIds($truckIds: [ID]!) {
    getLocationPlanningForTruckIds(truckIds: $truckIds) {
      planning {
        ...FragmentTruckPlanning
      }
    }
  }
`;

export const QUERY_GET_MY_ASSIGNED_ORDERS = gql`
  ${FRAGMENT_ORDER_COMPANY_OUTPUT}
  ${FRAGMENT_LOAD_FIELDS}
  query getMyAssignedOrders($paginate: PaginateInput) {
    getMyAssignedOrders(paginate: $paginate) {
      count
      orders {
        user {
          id
          name
          companyId
          email
        }
        orderNo
        transporter {
          ...FragmentOrderCompanyOutput
        }
        sender {
          ...FragmentOrderCompanyOutput
        }
        load {
          id
          userId
          shortId
          companyId
          shipper {
            id
            name
          }
          carrierId
          carrier {
            id
            name
          }
          status
          pickUp {
            address {
              id
              name
              address
              town
              county
              country
              latitude
              longitude
              contact {
                name
                phone
                email
              }
            }
            addressId
            from
            to
          }
          dropOff {
            address {
              id
              name
              address
              town
              county
              country
              latitude
              longitude
              contact {
                name
                phone
                email
              }
            }
            addressId
            from
            to
          }
          distance
          load {
            ...FragmentLoadFields
          }
          otherDetails
          price
          currency {
            id
            code
            symbol
          }
          paymentDelay
          contactId
          contact {
            id
            name
            phone
            email
          }
          truckId
          driverIds
          type
          bidding
        }
        costs {
          costWithoutVat
          costVatPercent
          costCurrency
          costWithVat
          costPayableUntil
        }
        budgetCosts {
          costWithoutVat
          costVatPercent
          costCurrency
          costWithVat
          costPayableUntil
        }
        vehicle {
          vehicleDetails
          vehiclePlate
          trailerPlate
        }
        specialInstructions
        termsConditions
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_GET_ORDER_BY_NUMBER = gql`
  ${FRAGMENT_ORDER_DATA_OUTPUT}
  query getOrderDataByNumber($orderNumber: String!) {
    getOrderDataByNumber(orderNumber: $orderNumber) {
      ...OrderDataOutputFragment
    }
  }
`;

export const QUERY_GET_ALL_TRUCK_LOCATIONS = gql`
  query getTrucksLocationsFull {
    getTrucksLocationsFull {
      lat
      lng
      speed
      accuracy
      isMoving
      altitude
      odometer
      activityType
      driver {
        id
        firstName
        lastName
        shortId
        phone
        email
      }
      company {
        id
        name
      }
      truck {
        id
        shortId
        brand
        plate
        weight
      }
    }
  }
`;

export const QUERY_GET_COMPANY_DATA_BY_SERIAL = gql`
  query getAnafData($cui: String!) {
    getAnafData(cui: $cui) {
      cui
      name
      email
      phone
      address
    }
  }
`;

export const QUERY_GET_MY_USERS = gql`
  ${FRAGMENT_CLIENT}
  query getMyUsers($paginate: PaginateInput, $filter: AllUsersFilterInput) {
    getMyUsers(paginate: $paginate, filter: $filter) {
      count
      users {
        ...FragmentClient
      }
    }
  }
`;

export const QUERY_GET_ALL_COMPANIES = gql`
  ${FRAGMENT_COMPANY_DATA_OUTPUT}
  query getAllCompanies(
    $paginate: PaginateInput
    $filter: AllCompaniesFilterInput
  ) {
    getAllCompanies(paginate: $paginate, filter: $filter) {
      count
      companies {
        ...CompanyDataFragment
      }
    }
  }
`;

export const QUERY_GET_MY_LOADS = gql`
  ${FRAGMENT_MY_LOADS_DATA_OUTPUT}
  query getMyLoads(
    $type: loadTypeEnum
    $filter: LoadFilterInput
    $paginate: PaginateInput
  ) {
    getMyLoads(type: $type, filter: $filter, paginate: $paginate) {
      count
      loads {
        ...LoadDataFragment
      }
    }
  }
`;

export const QUERY_GET_LOAD_BY_ID = gql`
  ${FRAGMENT_MY_LOADS_DATA_OUTPUT}
  query getLoadById($id: ID!) {
    getLoadById(id: $id) {
      ...LoadDataFragment
    }
  }
`;

export const QUERY_GET_LOAD_BY_SHORT_ID = gql`
  ${FRAGMENT_MY_LOADS_DATA_OUTPUT}
  query getLoadByShortId($shortId: String!) {
    getLoadByShortId(shortId: $shortId) {
      ...LoadDataFragment
    }
  }
`;

export const QUERY_GET_CURRENCIES = gql`
  ${FRAGMENT_CURRENCY}
  query getCurrencies {
    getCurrencies {
      currencies {
        ...FragmentCurrency
      }
    }
  }
`;

export const QUERY_GET_ALL_BIDS_FOR_LOAD_ID = gql`
  query getAllBidsForLoadId($filter: BidFilterInput, $paginate: PaginateInput) {
    getAllBidsForLoadId(filter: $filter, paginate: $paginate) {
      count
      bids {
        id
        loadId
        companyId
        company {
          id
          name
          cui
          role
          phone
        }
        bid
        vehicleCategory
        epAvailable
        paymentDelay
        dateLoad
        dateUnload
        fromLocationCity
        fromLocationLat
        fromLocationLng
        distanceToUnload
        createdAt
        updatedAt
        status
      }
    }
  }
`;

export const QUERY_GET_ALL_COMPANY_FRIENDSHIP = gql`
  ${FRAGMENT_COMPANY_RELATION}
  query getAllCompanyFriendship(
    $filter: CompanyRelationFilterInput
    $paginate: PaginateInput
  ) {
    getAllCompanyFriendship(filter: $filter, paginate: $paginate) {
      count
      relations {
        ...FragmentCompanyRelation
      }
    }
  }
`;

export const QUERY_SEARCH_COMPANY_BY = gql`
  query searchCompanyBy(
    $search: String!
    $searchBy: companySearchByFieldEnumGQL!
    $paginate: PaginateInput
  ) {
    searchCompanyBy(search: $search, searchBy: $searchBy, paginate: $paginate) {
      id
      noId
      name
      shortId
      address
      city
      county
      country
      phone
      email
      cui
    }
  }
`;

export const QUERY_GET_ORDER_TERMS = gql`
  query getOrderTerms {
    getOrderTerms
  }
`;

export const QUERY_GET_FINANCE_LAST_ORDER = gql`
  ${FRAGMENT_FINANCE}
  query getFinanceLastOrder($userId: ID, $currency: currencyEnum) {
    getFinanceLastOrder(userId: $userId, currency: $currency) {
      ...FragmentFinance
    }
  }
`;

export const QUERY_GET_FINANCE_LAST_ORDERS = gql`
  ${FRAGMENT_FINANCE}
  query getFinanceLastOrders(
    $userId: ID
    $currency: currencyEnum
    $numberOfMonths: Int
    $numberOfDays: Int
  ) {
    getFinanceLastOrders(
      userId: $userId
      currency: $currency
      numberOfMonths: $numberOfMonths
      numberOfDays: $numberOfDays
    ) {
      ...FragmentFinance
    }
  }
`;

export const QUERY_GET_FINANCE_ACCOUNT_BALANCE = gql`
  query getFinanceAccountBalance(
    $userId: ID
    $currency: currencyEnum
    $numberOfMonths: Int
    $numberOfDays: Int
  ) {
    getFinanceAccountBalance(
      userId: $userId
      currency: $currency
      numberOfMonths: $numberOfMonths
      numberOfDays: $numberOfDays
    ) {
      senderBudgetAverage
      accountBalance
      series {
        name
        data {
          x
          y
        }
      }
      currency
      userId
    }
  }
`;

export const QUERY_GET_FINANCE_BUDGET = gql`
  query getFinanceBudget($userId: ID, $currency: currencyEnum) {
    getFinanceBudget(userId: $userId, currency: $currency) {
      transporterBudget
      transporterBudgetPercent
      noOfOrders
      noOfProfitableOrders
      noOfUnprofitableOrders
      totalProfit
      totalProfitPercent
      currency
      userId
      targetRevenue
      targetProfit
      targetCurrency
    }
  }
`;

export const QUERY_GET_ALL_INVOICES = gql`
  ${FRAGMENT_INVOICE_DATA_OUTPUT}
  query getAllInvoices($paginate: PaginateInput, $filter: InvoiceFilterInput) {
    getAllInvoices(paginate: $paginate, filter: $filter) {
      count
      invoices {
        ...FragmentInvoiceDataOutput
      }
    }
  }
`;

export const QUERY_GET_TRUCK_BY_ID = gql`
  query getTruckById($truckId: ID!) {
    getTruckById(truckId: $truckId) {
      shortId
      vehicleCategory
      truckLicensePlate
      trailerLicensePlate
      price
      currency {
        id
        code
        symbol
      }
      vat
      truckFeatures
      available
    }
  }
`;

export const QUERY_GET_ALL_YLOAD_GPS_DATA = gql`
  query getAllYloadGpsData {
    getAllYloadGpsData {
      gpsShortId
      companyName
      companyId
      truckId
      licensePlate
      location
      vehicleCategory
      pricePerKm
      availability
      updatedAt
    }
  }
`;

export const QUERY_GET_FRIENDSHIP_REQUESTS = gql`
  ${FRAGMENT_COMPANY_RELATION}
  query getFriendshipRequests {
    getFriendshipRequests {
      count
      relations {
        ...FragmentCompanyRelation
      }
    }
  }
`;

export const QUERY_GET_ALL_TRUCKS_WITH_PLANNING = gql`
  ${FRAGMENT_TRUCK}
  query getAllTrucksWithPlanning(
    $filter: TruckPlanningFilterInput
    $paginate: PaginateInput
  ) {
    getAllTrucksWithPlanning(filter: $filter, paginate: $paginate) {
      count
      trucks {
        ...FragmentTruck
      }
    }
  }
`;

export const QUERY_GET_COMPANY_BY_ID = gql`
  ${FRAGMENT_COMPANY}
  query getCompanyById($companyId: ID!) {
    getCompanyById(companyId: $companyId) {
      ...FragmentCompany
    }
  }
`;

export const QUERY_GET_TARGETS = gql`
  ${FRAGMENT_TARGET}
  query getTargets($userId: ID!) {
    getTargets(userId: $userId) {
      ...FragmentTarget
    }
  }
`;

export const QUERY_GET_PENDING_LOAD_REQUESTS_COUNT = gql`
  query getPendingLoadRequestsCount {
    getPendingLoadRequestsCount
  }
`;

export const QUERY_GET_ALL_FRACHT_USERS = gql`
  ${FRAGMENT_FRACHT_MEMBERS}
  query getAllUsers(
    $paginate: PaginateInput
    $searchString: String
    $filter: AllFrachtUsersFilterInput
  ) {
    getAllUsers(
      paginate: $paginate
      searchString: $searchString
      filter: $filter
    ) {
      ...FragmentFrachtMembers
    }
  }
`;

export const QUERY_GET_FRACHT_USER_BY_ID = gql`
  ${FRAGMENT_FRACHT_USER_TYPE}
  query getFrachtUserById($userId: ID!) {
    getFrachtUserById(userId: $userId) {
      ...FragmentFrachtUserType
    }
  }
`;

export const QUERY_GET_FRACHT_BRANCH_BY_ID = gql`
  ${FRAGMENT_FRACHT_BRANCH_TYPE}
  query getFrachtBranchById($userId: ID!) {
    getFrachtBranchById(userId: $userId) {
      ...FragmentFrachtBranchType
    }
  }
`;

export const QUERY_GET_FRACHT_BRANCH_MEMBER_BY_ID = gql`
  ${FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE}
  query getFrachtBranchMemberById($id: ID!) {
    getFrachtBranchMemberById(id: $id) {
      ...FragmentFrachtBranchMemberType
    }
  }
`;

export const QUERY_GET_COMPLAINT_BY_ID = gql`
  ${FRAGMENT_COMPLAINT}
  query getComplaintById($id: ID!) {
    getComplaintById(id: $id) {
      ...FragmentComplaint
    }
  }
`;

export const QUERY_GET_ALL_AGENT_OFFICES = gql`
  ${FRAGMENT_FRACHT_AGENT_OFFICES}
  query getAllAgentOffices($name: String) {
    getAllAgentOffices(name: $name) {
      ...FragmentFrachtAgentOffices
    }
  }
`;

export const QUERY_GET_AGENT_CONTACTS_FOR_OFFICE = gql`
  ${FRAGMENT_FRACHT_OFFICE_AGENT_CONTACTS_TYPE}
  query getAgentContactsForOffice(
    $agentName: String
    $street: String
    $officeId: ID
  ) {
    getAgentContactsForOffice(
      agentName: $agentName
      street: $street
      officeId: $officeId
    ) {
      ...FragmentFrachtOfficeAgentContactsType
    }
  }
`;

export const QUERY_GET_AGENT_OFFICE_CONTACT_BY_ID = gql`
  ${FRAGMENT_FRACHT_OFFICE_AGENT_CONTACT_TYPE}
  query getAgentOfficeContactById($id: ID!) {
    getAgentOfficeContactById(id: $id) {
      ...FragmentFrachtOfficeAgentContactType
    }
  }
`;

export const QUERY_GET_AGENT_OFFICE_BY_ID = gql`
  ${FRAGMENT_FRACHT_AGENT_OFFICE_TYPE}
  query getAgentOfficeById($officeId: ID!) {
    getAgentOfficeById(officeId: $officeId) {
      ...FragmentFrachtAgentOfficeType
    }
  }
`;

export const QUERY_GET_ALL_USER_OFFICES = gql`
  ${FRAGMENT_FRACHT_USERS}
  query getAllUserOffices(
    $paginate: PaginateInput
    $searchString: String
    $filter: AllFrachtUsersFilterInput
  ) {
    getAllUserOffices(
      paginate: $paginate
      searchString: $searchString
      filter: $filter
    ) {
      ...FragmentFrachtUsers
    }
  }
`;

export const QUERY_GET_ALL_USERS_FOR_OFFICE = gql`
  ${FRAGMENT_FRACHT_USERS}
  query getAllUsersForOffice(
    $companyName: String
    $street: String
    $officeId: ID
  ) {
    getAllUsersForOffice(
      companyName: $companyName
      street: $street
      officeId: $officeId
    ) {
      ...FragmentFrachtUsers
    }
  }
`;

export const QUERY_CAN_EDIT_BRANCH = gql`
  query canEditBranch($frachtBranchMemberId: ID, $frachtBranchId: ID) {
    canEditBranch(
      frachtBranchMemberId: $frachtBranchMemberId
      frachtBranchId: $frachtBranchId
    )
  }
`;

export const QUERY_MANAGES_ANY_BRANCH = gql`
  query managesAnyBranch($frachtBranchMemberId: ID!) {
    managesAnyBranch(frachtBranchMemberId: $frachtBranchMemberId)
  }
`;

export const QUERY_CAN_EDIT_BRANCH_MEMBER = gql`
  query canEditBranchMember(
    $frachtBranchMemberId: ID
    $editedFrachtBranchMemberId: ID
  ) {
    canEditBranchMember(
      frachtBranchMemberId: $frachtBranchMemberId
      editedFrachtBranchMemberId: $editedFrachtBranchMemberId
    )
  }
`;

export const QUERY_GET_ALL_NEWSLETTERS = gql`
  ${FRAGMENT_NEWSLETTERS_TYPE}
  query getAllNewsletters($type: String, $paginate: PaginateInput) {
    getAllNewsletters(type: $type, paginate: $paginate) {
      ...FragmentNewslettersType
    }
  }
`;

export const QUERY_GET_ALL_DEALS = gql`
  ${FRAGMENT_DEAL}
  query getAllDeals($paginate: PaginateInput) {
    getAllDeals(paginate: $paginate) {
      count
      deals {
        ...FragmentDeal
      }
    }
  }
`;

export const QUERY_GET_DEAL_BY_ID = gql`
  ${FRAGMENT_DEAL}
  query getDealById($id: ID!) {
    getDealById(id: $id) {
      ...FragmentDeal
    }
  }
`;

export const QUERY_GET_EXTERNAL_NEWSLETTERS = gql`
  ${FRAGMENT_NEWSLETTERS_TYPE}
  query getExternalNewsletters($paginate: PaginateInput) {
    getExternalNewsletters(paginate: $paginate) {
      ...FragmentNewslettersType
    }
  }
`;

export const QUERY_GET_LAST_NEWSLETTER_TIMESTAMPS = gql`
  ${FRAGMENT_LAST_NEWSLETTERS_TIMESTAMP_TYPE}
  query getLastNewsletterTimestamps {
    getLastNewsletterTimestamps {
      ...FragmentLastNewsletterTimestampType
    }
  }
`;

export const QUERY_GET_CONTENT_TAGS = gql`
  ${FRAGMENT_CONTENT_TAG_TYPE}
  query getContentTags {
    getContentTags {
      ...FragmentContentTagType
    }
  }
`;

export const QUERY_GET_NEWSLETTER_BY_ID = gql`
  ${FRAGMENT_NEWSLETTER_TYPE}
  query getNewsletterById($id: ID!) {
    getNewsletterById(id: $id) {
      ...FragmentNewsletterType
    }
  }
`;

export const QUERY_CAN_CREATE_NEWSLETTER = gql`
  query canCreateNewsletter(
    $branchMemberId: ID
    $newsletterType: newsletterTypeEnum
  ) {
    canCreateNewsletter(
      branchMemberId: $branchMemberId
      newsletterType: $newsletterType
    )
  }
`;

export const QUERY_GET_AIR_SEA_PORTS = gql`
  query getAirSeaPorts(
    $paginate: PaginateInput
    $filter: AirSeaPortFilterInput
  ) {
    getAirSeaPorts(paginate: $paginate, filter: $filter) {
      count
      ports {
        id
        code
        address
        country
        city
        type
      }
    }
  }
`;

export const QUERY_GET_AIR_SEA_TRANSPORTERS = gql`
  query getAirSeaTransporters(
    $paginate: PaginateInput
    $filter: AirSeaTransporterFilterInput
  ) {
    getAirSeaTransporters(paginate: $paginate, filter: $filter) {
      count
      transporters {
        id
        name
        address
        contact
        phone
        cui
        tag
        type
        transporterType
      }
    }
  }
`;

export const QUERY_GET_SHIPMENT_BY_ID = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  ${FRAGMENT_SHIPMENT}
  query getShipmentById($id: ID!) {
    getShipmentById(id: $id) {
      ...FragmentShipment
    }
  }
`;

export const QUERY_GET_SHIPMENT_BY_SHORT_ID = gql`
  ${FRAGMENT_SHIPMENT}
  query getShipmentByShortId($shortId: String!) {
    getShipmentByShortId(shortId: $shortId) {
      ...FragmentShipment
    }
  }
`;

export const QUERY_GET_SHIPMENTS = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  ${FRAGMENT_SHIPMENT}
  query getShipments($paginate: PaginateInput, $filter: ShipmentFilterInput) {
    getShipments(paginate: $paginate, filter: $filter) {
      count
      shipments {
        ...FragmentShipment
      }
    }
  }
`;

export const QUERY_GET_CONTAINERS_BY_SHIPMENT_ID = gql`
  ${FRAGMENT_CONTAINER}
  query getContainersByShipmentId($shipmentId: ID!, $paginate: PaginateInput) {
    getContainersByShipmentId(shipmentId: $shipmentId, paginate: $paginate) {
      count
      containers {
        ...FragmentContainer
      }
    }
  }
`;

export const QUERY_GET_DOCUMENTS_BY_SHIPMENT_ID = gql`
  ${FRAGMENT_SHIPMENT_DOCUMENT}
  query getDocumentsByShipmentId($shipmentId: ID!, $paginate: PaginateInput) {
    getDocumentsByShipmentId(shipmentId: $shipmentId, paginate: $paginate) {
      count
      documents {
        ...FragmentShipmentDocument
      }
    }
  }
`;

export const QUERY_GET_BILLINGS_BY_SHIPMENT_ID = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  ${FRAGMENT_BILLING}
  query getBillingsByShipmentId($shipmentId: ID!, $paginate: PaginateInput, $date: DateFilterInput, $onlyDisplayRevenue: Boolean) {
    getBillingsByShipmentId(shipmentId: $shipmentId, paginate: $paginate, date: $date, onlyDisplayRevenue: $onlyDisplayRevenue) {
      count
      billings {
        ...FragmentBilling
      }
    }
  }
`;

export const QUERY_GET_AIR_SEA_TRANSPORTER_BY_ID = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  query getAirSeaTransporterById($id: ID!) {
    getAirSeaTransporterById(id: $id) {
      ...FragmentAirSeaTransporter
    }
  }
`;

export const QUERY_GENERATE_AIR_TRANSPORT_ORDER_PDF = gql`
  query generateAirTransportOrderPdf($airTransportOrderId: ID!) {
    generateAirTransportOrderPdf(airTransportOrderId: $airTransportOrderId)
  }
`;

export const QUERY_GET_MY_AIR_TRANSPORT_ORDERS = gql`
  ${FRAGMENT_AIR_TRANSPORT_ORDER}
  query getMyAirTransportOrders($paginate: PaginateInput) {
    getMyAirTransportOrders(paginate: $paginate) {
      count
      airTransportOrders {
        ...FragmentAirTransportOrder
      }
    }
  }
`;

export const QUERY_GET_AIRLINE_BY_AWB_PREFIX = gql`
  ${FRAGMENT_AIRLINE}
  query getAirlineByAwbPrefix($awbPrefix: String!) {
    getAirlineByAwbPrefix(awbPrefix: $awbPrefix) {
      ...FragmentAirline
    }
  }
`;

export const QUERY_GET_BANKING_DETAILS = gql`
  ${FRAGMENT_BANKING_DETAIL}
  query getBankingDetails(
    $paginate: PaginateInput
    $filter: BankingDetailFilterInput
  ) {
    getBankingDetails(paginate: $paginate, filter: $filter) {
      count
      bankingDetails {
        ...FragmentBankingDetail
      }
    }
  }
`;

export const QUERY_GET_BANKING_DETAIL_BY_ID = gql`
  ${FRAGMENT_BANKING_DETAIL}
  query getBankingDetailById($id: ID!) {
    getBankingDetailById(id: $id) {
      ...FragmentBankingDetail
    }
  }
`;

export const QUERY_GET_AIR_SEA_INVOICE_BY_ID = gql`
  ${FRAGMENT_INVOICE}
  query getAirSeaInvoiceById($id: ID!) {
    getAirSeaInvoiceById(id: $id) {
      ...FragmentInvoice
    }
  }
`;

export const QUERY_GET_AIR_SEA_INVOICES_BY_SHIPMENT_ID = gql`
  ${FRAGMENT_INVOICE}
  query getAirSeaInvoicesByShipmentId(
    $shipmentId: ID!
    $paginate: PaginateInput
  ) {
    getAirSeaInvoicesByShipmentId(
      shipmentId: $shipmentId
      paginate: $paginate
    ) {
      count
      invoices {
        ...FragmentInvoice
      }
    }
  }
`;

export const QUERY_GENERATE_AIR_SEA_INVOICE_PDF_BY_ID = gql`
  query generateAirSeaInvoicePdfById($id: ID!) {
    generateAirSeaInvoicePdfById(id: $id)
  }
`;

export const QUERY_GET_ALL_AIR_SEA_INVOICES = gql`
  ${FRAGMENT_INVOICE}
  query getAllAirSeaInvoices($paginate: PaginateInput) {
    getAllAirSeaInvoices(paginate: $paginate) {
      count
      invoices {
        ...FragmentInvoice
      }
    }
  }
`;

export const QUERY_SEARCH_USERS_BY = gql`
  ${FRAGMENT_CLIENT}
  query searchUsersBy($paginate: PaginateInput, $search: String) {
    searchUsersBy(paginate: $paginate, search: $search) {
      count
      users {
        ...FragmentClient
      }
    }
  }
`;

export const QUERY_GET_FINANCIALS_FOR_SHIPMENTS = gql`
  ${FRAGMENT_SHIPMENT_WITH_FINANCIAL_DATA_OUTPUT}
  query getFinancialsForShipments(
    $paginate: PaginateInput
    $filter: ShipmentFilterInput
  ) {
    getFinancialsForShipments(paginate: $paginate, filter: $filter) {
      ...FragmentShipmentWithFinancialDataOutput
    }
  }
`;

export const QUERY_GET_CASH_FLOW_FOR_SHIPMENTS = gql`
  ${FRAGMENT_SHIPMENT_WITH_CASH_FLOW_DATA_OUTPUT}
  query getCashFlowForShipments(
    $paginate: PaginateInput
    $filter: ShipmentCashFlowFilterInput
  ) {
    getCashFlowForShipments(paginate: $paginate, filter: $filter) {
      ...FragmentShipmentWithCashFlowDataOutput
    }
  }
`;

export const QUERY_GET_SHIPMENT_HISTORY = gql`
  ${FRAGMENT_SHIPMENT_HISTORY}
  query getShipmentHistory($shipmentId: ID!, $paginate: PaginateInput) {
    getShipmentHistory(shipmentId: $shipmentId, paginate: $paginate) {
      count
      history {
        ...FragmentShipmentHistory
      }
    }
  }
`;

export const QUERY_GET_SOCIAL_TERMS = gql`
  query getSocialTerms {
    getSocialTerms
  }
`;

export const QUERY_GET_ALL_COUNTRIES = gql`
  ${FRAGMENT_COUNTRY}
  query getAllCountries($paginate: PaginateInput, $filter: CountryFilter) {
    getAllCountries(paginate: $paginate, filter: $filter) {
      count
      countries {
        ...FragmentCountry
      }
    }
  }
`;

export const QUERY_GET_ALL_DEPARTMENTS = gql`
  ${FRAGMENT_DEPARTMENT}
  query getAllDepartments($paginate: PaginateInput, $filter: DepartmentFilter) {
    getAllDepartments(paginate: $paginate, filter: $filter) {
      count
      departments {
        ...FragmentDepartment
      }
    }
  }
`;

export const QUERY_GET_ALL_FINANCIAL_COSTS = gql`
  ${FRAGMENT_FINANCIAL_COST}
  query getAllFinancialCosts($paginate: PaginateInput, $filter: FinancialCostFilterInput) {
    getAllFinancialCosts(paginate: $paginate, filter: $filter) {
      count
      financialCosts {
        ...FragmentFinancialCost
      }
    }
  }
`;

export const QUERY_GET_ALL_FINANCIAL_PRESET_ACCOUNTS = gql`
  ${FRAGMENT_FINANCIAL_PRESET_ACCOUNT}
  query getAllFinancialPresetAccounts {
    getAllFinancialPresetAccounts {
      count
      financialPresetAccounts {
        ...FragmentFinancialPresetAccount
      }
    }
  }
`;

export const QUERY_GET_ALL_FINANCIAL_RECURRING_COSTS = gql`
  ${FRAGMENT_FINANCIAL_RECURRING_COST}
  query getAllFinancialRecurringCosts($paginate: PaginateInput, $filter: FinancialCostFilterInput) {
    getAllFinancialRecurringCosts(paginate: $paginate, filter: $filter) {
      count
      financialRecurringCosts {
        ...FragmentFinancialRecurringCost
      }
    }
  }
`;

export const QUERY_GET_FINANCIAL_COSTS_REPORT_DATA = gql`
  ${FRAGMENT_FINANCIAL_COST_VALUE}
  query getFinancialCostsReportData($date: DateFilterInput) {
    getFinancialCostsReportData(date: $date) {
       nonOperationalCosts {
        cost {
            ...FragmentFinancialCostValue
        }
				totalCostInEUR
				totalCostInRON
				totalCostInUSD
				totalRecurrentCostInEUR
				totalNonRecurrentCostInEUR
				totalRecurrentCostPercent
				totalNonRecurrentCostPercent
			}
			operationalCosts {
          cost {
            ...FragmentFinancialCostValue
          }
					totalCostInEUR
					totalCostInRON
					totalCostInUSD
					totalSeaCostInEUR
					totalAirCostInEUR
					totalRoadCostInEUR
					totalProjectsCostInEUR
					totalSeaCostPercent
					totalAirCostPercent
					totalRoadCostPercent
					totalProjectsCostPercent
			}
    }
  }
`;

export const QUERY_GET_FINANCIAL_DASHBOARD_DATA = gql`
  ${FRAGMENT_FINANCIAL_COST_VALUE}
  query getFinancialDashboardData($date: DateFilterInput) {
    getFinancialDashboardData(date: $date) {
       bankAccounts {
          total
          cost {
            ...FragmentFinancialCostValue
          }
       }
       netIncome {
          total
          cost {
            ...FragmentFinancialCostValue
          }
       }
       costs {
					total
					operationalTotal
					nonOperationalTotal
				}
				revenue{
					thisMonthTotal
					total
					ebitda
					totalWithSelectedDate
					chartLabel
          chartData
				}
				profitability{
					netProfit
					grossMargin
					totalPayable
          payable
          payableCost {
            ...FragmentFinancialCostValue
          }
          totalReceivable
          receivable
          receivableCost {
            ...FragmentFinancialCostValue
          }
          totalBillsOnPending
          billsOnPending
          billsOnPendingCost {
            ...FragmentFinancialCostValue
          }
          totalBillsOverdue
          billsOverdue
          billsOverdueCost {
            ...FragmentFinancialCostValue
          }
				}
				cashFlow {
					estimatedCost
    			estimatedRevenue
    			totalCashFlow
    			cashFlowDifference
   				 sea {
						estimatedCost
    				estimatedRevenue
    				totalCashFlow
    				cashFlowDifference
    				percentage
					}
					air {
						estimatedCost
    				estimatedRevenue
    				totalCashFlow
    				cashFlowDifference
    				percentage
					}
				}
				topPerforming{
					sea {
						percentage
						total
					}
					air{
						percentage
						total
					}
				}
    }
  }
`;

export const QUERY_GET_FINANCIAL_REVENUE_DATA = gql`
  ${FRAGMENT_FINANCIAL_COST_VALUE}
  ${FRAGMENT_SHIPMENT}
  query getFinancialRevenueData($date: DateFilterInput) {
    getFinancialRevenueData(date: $date) {
       shipments {
         ...FragmentShipment
       }
       today {
        total
        cost {
          ...FragmentFinancialCostValue
        }
      }
      next7Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
      }
      bankAccounts {
        total
        cost {
          ...FragmentFinancialCostValue
        }
      }
      last60Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      last30Days {
         total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      last7Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      next30Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      next60Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
    }
  }
`;

export const QUERY_GET_FINANCIAL_RECEIVABLE_DATA = gql`
  ${FRAGMENT_FINANCIAL_COST_VALUE}
  ${FRAGMENT_SHIPMENT}
  query getFinancialReceivableData($date: DateFilterInput) {
    getFinancialReceivableData(date: $date) {
       shipments {
         ...FragmentShipment
       }
      today {
        total
        cost {
          ...FragmentFinancialCostValue
        }
      }
      next7Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
      }
      bankAccounts {
        total
        cost {
          ...FragmentFinancialCostValue
        }
      }
      next30To60Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      next60To90Days {
         total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      next90To120Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      over30Days {
          total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      over60Days {
          total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
      over120Days {
        total
        cost {
          ...FragmentFinancialCostValue
        }
        totalInvoices
      }
    }
  }
`;

export const QUERY_GET_ALL_FINANCIAL_BANK_ACCOUNTS = gql`
  ${FRAGMENT_COMPANY}
  ${FRAGMENT_CLIENT}
  query getAllFinancialBankAccounts {
    getAllFinancialBankAccounts {
      id
      userId
      user {
        ...FragmentClient
      }
      companyId
      company {
        ...FragmentCompany
      }
      balanceDate
      bankName
      personInCharge
      otherInformation
      accounts {
        currency
        amount
      }
      createdAt
      updatedAt
    }
  }
`;

export const QUERY_GET_FINANCIAL_CASH_FLOW_DATA = gql`
  ${FRAGMENT_SHIPMENT}
  ${FRAGMENT_FINANCIAL_COST_VALUE}
  query getFinancialCashFlowData($date: DateFilterInput) {
    getFinancialCashFlowData(date: $date) {
       estimateCosts {
          total
          byCurrency {
            ...FragmentFinancialCostValue
          }
       }
       estimateReceivable {
          total
          byCurrency {
            ...FragmentFinancialCostValue
          }
       }
       difference {
          total
          byCurrency {
            ...FragmentFinancialCostValue
          }
       }
       count
       shipments {
          ...FragmentShipment
       }
    }
  }
`;

