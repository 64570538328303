import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import {moduleGuard} from './guards/app-module.guard';
import {AppModulesEnum} from './types/user/user.types';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'social' },
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'social',
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.routes'
            ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.routes'
            ),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import(
            'app/modules/auth/reset-password/reset-password.routes'
            ),
      },
      {
        path: 'change-pass-with-code/:emailCode',
        loadChildren: () =>
          import(
            'app/modules/auth/change-pass-with-code/change-pass-with-code.routes'
            ),
      },
      {
        path: 'validate-email/:code',
        loadChildren: () =>
          import(
            'app/modules/auth/validate-email/validate-email.routes'
            ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.routes'),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.routes'),
      },
      {
        path: 'order-details',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/truck-freight/order/order-details/order-details.routes'
            ),
      },
      {
        path: 'shipment',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/shared/single-shipment/single-shipment.routes'
            ),
      },
    ],
  },

  // Unauthenticated users - centered layout
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'centered',
    },
    children: [
      {
        path: 'external-news',
        loadChildren: () =>
          import('app/modules/pages/communication/external-news/external-news.routes'),
      }
    ],
  },


  // Auth routes for admin users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'admin/clients',
        loadChildren: () =>
          import('app/modules/admin/clients/clients.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.routes'),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import(
            'app/modules/auth/unlock-session/unlock-session.routes'
            ),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // Communication
      {
        path: 'social',
        children: [
          {
            path: '',
            loadChildren: () =>
              import(
                'app/modules/pages/communication/social/social.routes'
                ),
          },
          {
            path: 'news',
            loadChildren: () =>
              import(
                'app/modules/pages/communication/news/news.routes'
                ),
          },
        ],
      },
      {
        path: 'company-news',
        loadChildren: () =>
          import(
            'app/modules/pages/communication/company-news/company-news.routes'
            ),
      },
      {
        path: 'organisation',
        loadChildren: () =>
          import(
            'app/modules/pages/communication/offices/offices.routes'
            ),
      },
      {
        path: 'conversations',
        loadChildren: () =>
          import(
            'app/modules/pages/communication/chat/chat.routes'
            ),
      },

      // Sales

      {
        path: 'sales',
        children: [
          {
            path: 'pipeline',
            loadChildren: () =>
              import(
                'app/modules/pages/sales/pipeline/pipeline.routes'
                ),
          },
          {
            path: 'requests',
            loadChildren: () =>
              import(
                'app/modules/pages/sales/requests/requests.routes'
                ),
          }
        ]
      },
      // CRM
      {
        path: 'crm',
        canActivate: [moduleGuard],
        data: {
          module: AppModulesEnum.CRM,
        },
        children: [
          {
            path: 'accounts',
            loadChildren: () =>
              import(
                'app/modules/pages/crm/accounts/accounts.routes'
                ),
          },
          {
            path: 'contacts',
            loadChildren: () =>
              import(
                'app/modules/pages/crm/contacts/contacts.routes'
                ),
          },
          {
            path: 'opportunities',
            loadChildren: () =>
              import(
                'app/modules/pages/crm/opportunities/opportunities.routes'
                ),
          },
          {
            path: 'tasks',
            loadChildren: () =>
              import(
                'app/modules/pages/crm/tasks/tasks.routes'
                ),
          },
        ]
      },

      // Content
      {
        path: 'content',
        canActivate: [moduleGuard],
        data: {
          module: AppModulesEnum.CONTENT,
        },
        children: [
          {
            path: 'file-manager',
            loadChildren: () =>
              import(
                'app/modules/pages/file-manager/file-manager.routes'
                ),
          },
        ]
      },

      // Content
      {
        path: 'content',
        canActivate: [moduleGuard],
        data: {
          module: AppModulesEnum.CONTENT,
        },
        children: [
          {
            path: 'file-manager',
            loadChildren: () =>
              import(
                'app/modules/pages/file-manager/file-manager.routes'
                ),
          },
        ]
      },

      // HR
      {
        path: 'hr',
        canActivate: [moduleGuard],
        data: {
          module: AppModulesEnum.HR,
        },
        children: [
          {
            path: 'leave-request',
            loadChildren: () =>
              import(
                'app/modules/pages/hr/leave-request/leave-request.routes'
                ),
          },
        ]
      },

      // Operational

      // Sea freight

      {
        path: 'sea-freight',
        children: [
          {
            path: 'shipments',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-shipment/sea-freight-shipment.routes'
                ),
          },
          {
            path: 'partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-transporter/sea-freight-transporter.routes'
                ),
          },
          {
            path: 'banking-partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/shared/banking-partners/banking-partners.routes'
                ),
          },
          {
            path: 'invoices',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-invoice/sea-freight-invoice.routes'
                ),
          },
          {
            path: 'financials',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/sea-freight/sea-freight-financial/sea-freight-financial.routes'
                ),
          },
        ],
      },

      // Air Freight
      {
        path: 'air-freight',
        children: [
          {
            path: 'shipments',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-shipment/air-freight-shipment.routes'
                ),
          },
          {
            path: 'air-transport-order',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-order/air-freight-order.routes'
                ),
          },
          {
            path: 'partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-transporter/air-freight-transporter.routes'
                ),
          },
          {
            path: 'banking-partners',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/shared/banking-partners/banking-partners.routes'
                ),
          },
          {
            path: 'invoices',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-invoice/air-freight-invoice.routes'
                ),
          },
          {
            path: 'financials',
            loadChildren: () =>
              import(
                'app/modules/pages/operational/air-freight/air-freight-financial/air-freight-financial.routes'
                ),
          },
        ],
      },

      // Truck Freight
      {
        path: 'order',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/truck-freight/order/order.routes'
            ),
      },
      {
        path: 'load',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/truck-freight/load/load.routes'
            ),
      },
      {
        path: 'financials',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/truck-freight/financials/financials.routes'
            ),
      },
      {
        path: 'truck-status',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/truck-freight/truck-status/truck-status.routes'
            ),
      },
      {
        path: 'localization',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/truck-freight/localization/localization.routes'
            ),
      },
      {
        path: 'gps-list',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/truck-freight/gps-list/gps-list.routes'
            ),
      },
      {
        path: 'financial-costs',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/financials/financials.routes'
            ),
      },

      // Profile
      {
        path: 'account',
        loadChildren: () =>
          import(
            'app/modules/pages/user-account/user-account.routes'
            ),
      },

      // Others
      {
        path: 'companies',
        loadChildren: () =>
          import(
            'app/modules/pages/operational/companies/companies.routes'
            ),
      },

      // 404 & Catch all
      // {
      //     path: '404-not-found',
      //     pathMatch: 'full',
      //     loadChildren: () =>
      //         import(
      //             'app/modules/admin/pages/error/error-404/error-404.routes'
      //         ),
      // },
      // { path: '**', redirectTo: '404-not-found' },
    ],
  },
];
