import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { CometChat } from '@cometchat-pro/chat';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-accept-tc-modal',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    NgIf,
  ],
  templateUrl: './accept-tc-modal.component.html',
  styleUrl: './accept-tc-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AcceptTcModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _router: Router,
    public _userService: UserService,
    private dialogRef: MatDialogRef<AcceptTcModalComponent>,
    private _authService: AuthService,
    public _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  signOut(): void {
    CometChat.logout().then();
    sessionStorage.removeItem('menuAppearance');
    this._authService.signOut().subscribe();
    this.dialogRef.close();
    this._router.navigate(['/sign-out']).then();
    this._cdr.markForCheck();
  }

  acceptSocialTerms(): void {
    this._userService.acceptSocialTerms().subscribe();
    this.dialogRef.close();
  }
}
