/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { AppModulesEnum } from '../../types/user/user.types';
import { solar_cardTransferOutline, solar_chartSquareOutline } from '../../../../public/icons/solar-outline';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'communication',
    title: 'Network',
    type: 'group',
    icon: 'solar_usersGroupRoundedOutline',
    children: [
      {
        id: 'news',
        title: 'Social',
        type: 'basic',
        icon: 'solar_documentAddOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/social',
      },
      {
        id: 'companyNews',
        title: 'Company News',
        type: 'basic',
        icon: 'solar_inboxLineOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/company-news'
      },
      {
        id: "organisationFrachtContacts",
        title: "Contacts",
        type: "basic",
        icon: "solar_notebookSquareMenuOutline",
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: "/organisation/offices"
      },
      {
        id: "organisationFrachtAgents",
        title: "Offices",
        type: "basic",
        icon: "solar_caseRoundOutline",
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: "/organisation/map"
      },
      {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'solar_chatRoundOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/conversations'
      }
    ],
  },
  {
    id: "content",
    title: "Content",
    meta: {
      module: AppModulesEnum.CONTENT,
    },
    type: "group",
    icon: "solar_folderOutline",
    children: [
      {
        id: "fileManagement",
        title: "File Manager",
        type: "basic",
        icon: "solar_folderOutline",
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: "/content/file-manager"
      }
    ]
  },
  {
    id: 'crm',
    title: 'CRM',
    meta: {
      module: AppModulesEnum.CRM
    },
    type: 'group',
    icon: 'solar_layersMinimalisticOutline',
    children: [
      {
        id: 'contacts',
        title: 'Contacts',
        type: 'basic',
        icon: 'solar_userOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/crm/contacts',
      },
      {
        id: 'accounts',
        title: 'Accounts',
        type: 'basic',
        icon: 'solar_plateOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/crm/accounts',
      },
      {
        id: 'opportunities',
        title: 'Opportunities',
        type: 'basic',
        icon: 'solar_alignLeftOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/crm/opportunities',
      },
      {
        id: 'tasks',
        title: 'Tasks',
        type: 'basic',
        icon: 'solar_sortByTimeOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/crm/tasks',
      },
    ],
  },
  {
    id: 'operational',
    title: 'Operational',
    type: 'group',
    icon: 'solar_routingOutline',
    children: [
      {
        id: 'seaFreight',
        title: 'Sea Freight',
        type: 'collapsable',
        icon: 'solar_cargoShipOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/sea-freight',
        children: [
          {
            id: 'seaFreightOrders',
            title: 'Shipments',
            type: 'basic',
            icon: 'solar_documentOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/sea-freight/shipments',
          },
          {
            id: 'seaFreightTransporters',
            title: 'Partners',
            type: 'basic',
            icon: 'solar_userPlusBrokenOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/sea-freight/partners',
          },
          {
            id: 'seaFreightBankingDetails',
            title: 'Banking Partners',
            type: 'basic',
            icon: 'solar_usersGroupTwoRoundedOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/sea-freight/banking-partners',
          },
          {
            id: 'seaFreightInvoices',
            title: 'Invoices',
            type: 'basic',
            icon: 'solar_cardTransferOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/sea-freight/invoices',
          },
          {
            id: 'seaFreightFinancials',
            title: 'Financials',
            type: 'basic',
            icon: 'solar_chartSquareOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/sea-freight/financials',
          },
        ]
      },
      {
        id: 'airFreight',
        title: 'Air Freight',
        type: 'collapsable',
        icon: 'solar_airplaneOutline',
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: '/air-freight',
        children: [
          {
            id: 'airFreightOrders',
            title: 'Shipments',
            type: 'basic',
            icon: 'solar_documentOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/air-freight/shipments',
          },
          {
            id: 'airFreightOrders',
            title: 'Waybills',
            type: 'basic',
            icon: 'solar_fileTextOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/air-freight/air-transport-order',
          },
          {
            id: 'airFreightTransporters',
            title: 'Partners',
            type: 'basic',
            icon: 'solar_userPlusBrokenOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/air-freight/partners',
          },
          {
            id: 'airFreightBankingDetails',
            title: 'Banking Partners',
            type: 'basic',
            icon: 'solar_usersGroupTwoRoundedOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/air-freight/banking-partners',
          },
          {
            id: 'airFreightInvoices',
            title: 'Invoices',
            type: 'basic',
            icon: 'solar_cardTransferOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/air-freight/invoices',
          },
          {
            id: 'airFreightFinancials',
            title: 'Financials',
            type: 'basic',
            icon: 'solar_chartSquareOutline',
            classes: {
              icon: 'icon-size-5',
            },
            link: '/air-freight/financials',
          },
        ]
      },
      {
        id: "financialCosts",
        title: "Financials",
        type: "basic",
        icon: "solar_chartOutline",
        classes: {
          icon: 'icon-size-5 mr-3',
        },
        link: "/financial-costs"
      }
    ],
  }
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'communication',
    title: 'Network',
    type: 'aside',
    icon: 'solar_usersGroupRoundedOutline',
    classes: {
      icon: 'icon-size-5',
    },
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "content",
    title: "Content",
    meta: {
      module: AppModulesEnum.CONTENT,
    },
    type: "aside",
    icon: "solar_folderOutline",
    classes: {
      icon: 'icon-size-5',
    },
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'crm',
    title: 'CRM',
    meta: {
      module: AppModulesEnum.CRM
    },
    type: 'aside',
    icon: 'solar_layersMinimalisticOutline',
    classes: {
      icon: 'icon-size-5',
    },
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'operational',
    title: 'Operational',
    type: 'aside',
    icon: 'solar_routingOutline',
    classes: {
      icon: 'icon-size-5',
    },
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
