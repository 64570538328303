import { provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withHashLocation,
  withInMemoryScrolling, withPreloading,
} from '@angular/router';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { provideFuse } from '@fuse';
import { provideTransloco } from '@jsverse/transloco';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { environment } from 'environments/environment';
import { provideAuth } from './core/providers/auth.provider';
import { MockApiService } from './mock-api';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(
      appRoutes,
      withHashLocation(),
      // withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    provideApollo(() => {
      const httpLink = inject(HttpLink);

      return {
        link: ApolloLink.from([httpLink.create({ uri: environment.graphqlUrl })]),
        cache: new InMemoryCache({
          addTypename: false,
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
      };
    }),
    importProvidersFrom(
      MatGoogleMapsAutocompleteModule.forRoot(environment.mapsKey),
    ),

    // Material Date Adapter
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },

    // Transloco Config
    provideTransloco({
      config: {
      //   availableLangs: [
      //     {
      //       id: 'en',
      //       label: 'English',
      //     },
      //     {
      //       id: 'tr',
      //       label: 'Turkish',
      //     },
      //   ],
      //   defaultLang: 'en',
      //   fallbackLang: 'en',
      //   reRenderOnLangChange: true,
      //   prodMode: !isDevMode(),
      },
      // loader: TranslocoHttpLoader,
    }),
    provideAppInitializer(() => {}),

    // Fuse
    provideAuth(),
    provideIcons(),
    provideFuse({
      mockApi: {
        delay: 0,
        service: MockApiService,
      },
      fuse: {
        layout: 'compact',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme: 'theme-default',
        themes: [
          {
            id: 'theme-default',
            name: 'Default',
          },
          {
            id: 'theme-brand',
            name: 'Brand',
          },
          {
            id: 'theme-teal',
            name: 'Teal',
          },
          {
            id: 'theme-rose',
            name: 'Rose',
          },
          {
            id: 'theme-purple',
            name: 'Purple',
          },
          {
            id: 'theme-amber',
            name: 'Amber',
          },
        ],
      },
    }),
  ],
};
