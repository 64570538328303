import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import {forkJoin, map, Observable, of, ReplaySubject, switchMap, take, tap} from 'rxjs';
import {UserService} from '../../services/user/user.service';
import {AppModulesEnum, RoleAccessLevels, UserRolesEnum, WorkloadUserRolesEnum} from '../../types/user/user.types';
import {FuseNavigationItem} from '../../../@fuse/components/navigation';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _httpClient = inject(HttpClient);
  private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);
  private _userPermissions: { module: AppModulesEnum; role: WorkloadUserRolesEnum }[] = [];

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */

  constructor(private _userService: UserService) {
  }

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    return this._userService.user$.pipe(
      take(1), // Get the user data once and complete
      switchMap((user) => {
        this._userPermissions = user.permissions || [];
        return this._httpClient.get<Navigation>('api/common/navigation');
      }),
      map((navigation) => {
        return this.filterNavigationAccess(navigation);
      }),
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }

  private filterNavigationAccess(navigation: Navigation): Navigation {
    const userPermissions = this._userPermissions;

    const filterItems = (items: FuseNavigationItem[]): FuseNavigationItem[] => {
      return items
        .filter((item) => {
          const module = item.meta?.module;
          const minimumRole = item.meta?.role;

          if (!module) {
            return true;
          }

          const userPermission = userPermissions.find((perm) => perm.module === module);
          if (!userPermission) {
            return false;
          }
          return !minimumRole || RoleAccessLevels[userPermission.role] >= RoleAccessLevels[minimumRole];
        })
        .map((item) => ({
          ...item,
          children: item.children ? filterItems(item.children) : undefined,
        }));
    };

    return {
      compact: filterItems(navigation.compact),
      default: filterItems(navigation.default),
      futuristic: filterItems(navigation.futuristic),
      horizontal: filterItems(navigation.horizontal),
    };
  }
}
