import { Company } from '../company/company.types';
import { IFrachtMember } from '../offices/offices.types';
import { DynamicFilterType } from "../../components/filter/filter.interface";

export interface User {
  avatar: string;
  status: string;
  userId: string;
  shortId: null;
  id: string;
  name: string;
  company: Company;
  companyId: string;
  cui: null;
  email: string;
  emailStatus: number;
  emailSent: number;
  phone: string;
  country: null;
  county: null;
  town: null;
  address: null;
  role: string;
  photo: string;
  active: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  transportLicence: null;
  documents: null;
  oneSignalIdHash: string;
  oneSignalActive: number;
  oneSignalTag: OneSignalTag[];
  lastLogin: string;
  profileThumbUrl: string;
  profilePhotoUrl: string;
  lastMarketNewsVisit: string;
  lastSocialVisit: string;
  lastCompanyNewsVisit: string;
  frachtBranchMember: IFrachtMember;
  lastActive: string;
  acceptedSocialTerms: boolean;
  acceptedSocialTermsTimestamp: string;
  permissions: UserPermissionsType;
  tenantId: string;
}

export type UserPermissionsType = {
  tenantId: string;
  userId: string;
  roles: {
    module: AppModulesEnum;
    role: WorkloadUserRolesEnum;
  }[];
}

export enum AppModulesEnum {
  SOCIAL = 'SOCIAL',
  CRM = 'CRM',
  OPERATIONAL = 'OPERATIONAL',
  REPORTING = 'REPORTING',
  HR = 'HR',
  CONTENT = 'CONTENT',
}

export enum CrmServicesEnum {
  ACCOUNTS = 'ACCOUNTS',
  CONTACTS = 'CONTACTS',
  OPPORTUNITIES = 'OPPORTUNITIES',
  TASKS = 'TASKS',
}

export enum HrServicesEnum {
  LEAVE_REQUESTS = 'LEAVE_REQUESTS',
}

export type WorkloadServicesEnum = CrmServicesEnum | HrServicesEnum;

export enum WorkloadUserRolesEnum {
  SUPER_ADMINISTRATOR = 'SUPER_ADMINISTRATOR',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUPERVISOR = 'SUPERVISOR',
  POWER_USER = 'POWER_USER',
  USER = 'USER',
  GUEST = 'GUEST',
}

export const RoleAccessLevels: { [key in WorkloadUserRolesEnum]: number } = {
  [WorkloadUserRolesEnum.SUPER_ADMINISTRATOR]: 5,
  [WorkloadUserRolesEnum.ADMINISTRATOR]: 4,
  [WorkloadUserRolesEnum.SUPERVISOR]: 3,
  [WorkloadUserRolesEnum.POWER_USER]: 2,
  [WorkloadUserRolesEnum.USER]: 1,
  [WorkloadUserRolesEnum.GUEST]: 0,
};


export enum UserStatusEnum {
  inactive = 0,
  active = 1,
  blocked = 2,
  blacklist = 3,
  pending = 4,
}

export enum UserRolesEnum {
  // admin = 'admin',
  owner = 'owner',
  member = 'member',
  shipper = 'shipper',
  carrier = 'carrier',
  expeditionHouse = 'expeditionHouse',
  moderator = 'moderator',
  support = 'support',
}

export interface OneSignalTag {
  key: string;
  value: string;
}

export interface EditCompanyInput {
  name: string;
  cui: string;
  country: string;
  county: string;
  city: string;
  address: string;
  phone: string;
  website: string;
}

export interface IUserFilter {
  searchString: string
}

export interface IUserPartial {
  id: string;
  name: string;
}

export type IUserPreference  = Partial <{
  id?: string;
  name: string;
  userId: string;
  module: AppModulesEnum;
  // Update this with a union of all service enums as needed
  service: CrmServicesEnum;
  filters: DynamicFilterType[];
}>;
